import revive_payload_client_loo8gMoKHE from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@3.29.4_typescript@5._w243aqnaq6ukir6yniknaeiaiy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UtG8MePHyp from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@3.29.4_typescript@5._w243aqnaq6ukir6yniknaeiaiy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pc2Sab5jQm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@3.29.4_typescript@5._w243aqnaq6ukir6yniknaeiaiy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import highchart_J5yQudteOk from "/vercel/path0/apps/zefire-spa/plugins/highchart.ts";
import check_outdated_build_client_Q7F0I0i7ma from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@3.29.4_typescript@5._w243aqnaq6ukir6yniknaeiaiy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_F5Roq8lpDV from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@3.29.4_typescript@5.4.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/zefire-spa/.nuxt/components.plugin.mjs";
import prefetch_client_H8yso6ae6Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@3.29.4_typescript@5._w243aqnaq6ukir6yniknaeiaiy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_1ntNouMngS from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_nuxt@3.11.1_rollup@3.29.4_vite@5.2.3_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_cCfvT8HseT from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_nuxt@3.11.1_rollup@3.29.4_vite@5.2.3_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_AUZy1wIp8D from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_nuxt@3.11.1_rollup@3.29.4_vite@5.2.3_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_J3WhyjqvPK from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.3_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_Nj8Ld712IF from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@3.29.4_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_Vlgz8WnrXo from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@3.29.4_typescript@5._w243aqnaq6ukir6yniknaeiaiy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/vercel/path0/apps/zefire-spa/plugins/auth.ts";
import custom_directives_gtEpGpM7lF from "/vercel/path0/apps/zefire-spa/plugins/custom-directives.ts";
import nprogress_OPYCKjD1DE from "/vercel/path0/apps/zefire-spa/plugins/nprogress.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/apps/zefire-spa/plugins/sentry.ts";
import tippy_xFBYqfAPCu from "/vercel/path0/apps/zefire-spa/plugins/tippy.ts";
import v_calendar_dxaVzst4iS from "/vercel/path0/apps/zefire-spa/plugins/v-calendar.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/apps/zefire-spa/plugins/vue-query.ts";
export default [
  revive_payload_client_loo8gMoKHE,
  unhead_UtG8MePHyp,
  router_pc2Sab5jQm,
  highchart_J5yQudteOk,
  check_outdated_build_client_Q7F0I0i7ma,
  plugin_vue3_F5Roq8lpDV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_H8yso6ae6Z,
  slideovers_1ntNouMngS,
  modals_cCfvT8HseT,
  colors_AUZy1wIp8D,
  plugin_client_J3WhyjqvPK,
  i18n_Nj8Ld712IF,
  chunk_reload_client_Vlgz8WnrXo,
  auth_vT9JWWT9pN,
  custom_directives_gtEpGpM7lF,
  nprogress_OPYCKjD1DE,
  sentry_3AyO8nEfhE,
  tippy_xFBYqfAPCu,
  v_calendar_dxaVzst4iS,
  vue_query_wrmMkNpEpe
]
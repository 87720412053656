/* eslint-disable */
import { Zodios, type ZodiosOptions, makeApi } from "@zodios/core";
import { z } from "zod";

export const CompanyKind = z.enum([
  "company",
  "assetowner",
  "assetmanager",
  "holding",
  "offtaker",
  "tso",
  "dso",
]);
export type CompanyKind = z.infer<typeof CompanyKind>;

export const CompanySchemaShort = z.object({
  uuid: z.string().uuid(),
  kind: CompanyKind,
  name: z.string(),
});
export type CompanySchemaShort = z.infer<typeof CompanySchemaShort>;

export const MeilisearchInfos = z.object({
  datastudio_index: z.string(),
  topology_index: z.string(),
  api_key: z.string().nullish(),
});
export type MeilisearchInfos = z.infer<typeof MeilisearchInfos>;

export const User = z.object({
  sub: z.string(),
  email: z.string().nullish(),
  name: z.string().nullish(),
  permissions: z.array(
    z.union([
      z.enum([
        "read:timeseries",
        "read:partner",
        "read:contract",
        "read:market",
        "read:document",
        "write:document",
        "upload:document",
        "read:supervision",
        "write:supervision",
        "read:event",
        "write:event",
        "write:goldensource",
        "write:datahealth",
        "write:metric",
        "read:invoice",
        "write:invoice",
        "write:invoice_accounted",
        "write:contract",
        "write:geo",
        "write:market",
        "read:note",
        "write:note",
        "read:datastudio",
        "read:finance",
        "read:tender",
        "write:tender",
      ]),
      z.string(),
    ])
  ),
  org_id: z.string(),
  company: CompanySchemaShort,
  meilisearch: MeilisearchInfos.nullish(),
});
export type User = z.infer<typeof User>;

export const ValidationError = z.object({
  loc: z.array(z.union([z.string(), z.number()])),
  msg: z.string(),
  type: z.string(),
});
export type ValidationError = z.infer<typeof ValidationError>;

export const HTTPValidationError = z.object({
  detail: z.array(ValidationError).nullish(),
});
export type HTTPValidationError = z.infer<typeof HTTPValidationError>;

export const TimeSeries = z.object({
  index: z.array(z.number()).nullish(),
  data: z.array(z.number()).nullish(),
  name: z.string().nullish(),
});
export type TimeSeries = z.infer<typeof TimeSeries>;

export const SiteSummarySchema = z.object({
  capacity: z.number(),
  site_number: z.number().int(),
});
export type SiteSummarySchema = z.infer<typeof SiteSummarySchema>;

export const ContractStatus = z.enum([
  "no_status",
  "connection_requested",
  "commissioned",
  "contract_available",
  "signed",
  "countersigned",
  "litigation",
]);
export type ContractStatus = z.infer<typeof ContractStatus>;

export const ContractSummarySchema = z.object({
  contract_number: z.number().int(),
  contract_status: ContractStatus,
});
export type ContractSummarySchema = z.infer<typeof ContractSummarySchema>;

export const AddresseSchema = z.object({
  name: z.string().nullish(),
  address: z.string().nullish(),
  postal_code: z.string().nullish(),
  postal_box: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
});
export type AddresseSchema = z.infer<typeof AddresseSchema>;

export const ContactInfoSchema = z.object({
  firstname: z.string().nullish(),
  lastname: z.string().nullish(),
  team_name: z.string().nullish(),
  position: z.string().nullish(),
  email: z.string().nullish(),
  company_phone: z.string().nullish(),
  mobile_phone: z.string().nullish(),
  image_link: z.string().nullish(),
});
export type ContactInfoSchema = z.infer<typeof ContactInfoSchema>;

export const BaseCompanySchema = z.object({
  uuid: z.string().uuid(),
  kind: CompanyKind,
  name: z.string(),
  url: z.string().nullish(),
  email: z.string().nullish(),
  telephone: z.string().nullish(),
  registration_number: z.string().nullish(),
  registration_office: z.string().nullish(),
  vat_number: z.string().nullish(),
  legal_type: z.string().nullish(),
  iban_code: z.string().nullish(),
  bic_code: z.string().nullish(),
  addresses: z.array(AddresseSchema),
  contact_infos: z.array(ContactInfoSchema),
});
export type BaseCompanySchema = z.infer<typeof BaseCompanySchema>;

export const CountrySchema = z.object({
  name: z.string().nullish(),
  code: z.string(),
});
export type CountrySchema = z.infer<typeof CountrySchema>;

export const TenderRequestCreate = z.object({
  external_id: z.string(),
  counterpart: z.string(),
  country: CountrySchema,
  deadline: z.coerce.date(),
  sites: z.array(z.string()).nullish(),
  folder: z.string(),
});
export type TenderRequestCreate = z.infer<typeof TenderRequestCreate>;

export const RegionSchema = z.object({
  name: z.string().nullish(),
  code: z.string(),
  uuid: z.string().uuid(),
});
export type RegionSchema = z.infer<typeof RegionSchema>;

export const Portfolio = z.object({
  name: z.string(),
  uuid: z.string().uuid(),
});
export type Portfolio = z.infer<typeof Portfolio>;

export const NetworkVoltageSchema = z.object({
  name: z.string().nullish(),
  code: z.string(),
});
export type NetworkVoltageSchema = z.infer<typeof NetworkVoltageSchema>;

export const SiteStatus = z.enum([
  "no_status",
  "development",
  "financing",
  "connection_requested",
  "construction",
  "waiting_commissioning",
  "commissioned",
  "on_hold",
  "dismanteled",
]);
export type SiteStatus = z.infer<typeof SiteStatus>;

export const DeliveryPointCodeKind = z.enum(["MALO", "CARD", "EAN"]);
export type DeliveryPointCodeKind = z.infer<typeof DeliveryPointCodeKind>;

export const DeliveryPointType = z.enum([
  "injection",
  "production",
  "consumption",
  "self_consumption",
]);
export type DeliveryPointType = z.infer<typeof DeliveryPointType>;

export const MeterSegment = z.enum([
  "p1",
  "p2",
  "p3",
  "p4",
  "c1",
  "c2",
  "c3",
  "c4",
  "c5",
]);
export type MeterSegment = z.infer<typeof MeterSegment>;

export const NetworkCfg = z.object({
  connection_request: z.coerce.date().nullish(),
  idc: z.string().nullish(),
  contractual_state: z.string().nullish(),
  segment: MeterSegment.nullish(),
  meter_model: z.string().nullish(),
  network_raw: z.record(z.any()).nullish(),
});
export type NetworkCfg = z.infer<typeof NetworkCfg>;

export const DSOSchema = z.object({
  name: z.string().nullish(),
  code: z.string(),
});
export type DSOSchema = z.infer<typeof DSOSchema>;

export const DeliveryPointKind = z.enum(["meter", "headmeter", "submeter"]);
export type DeliveryPointKind = z.infer<typeof DeliveryPointKind>;

export const TurbineType = z.enum(["onshore", "offshore"]);
export type TurbineType = z.infer<typeof TurbineType>;

export const WindTurbineSchema = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  hub_height: z.number().nullish(),
  name: z.string(),
  model: z.string(),
  manufacturer: z.string(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  type: TurbineType,
  powercurve: z.array(z.array(z.unknown())),
});
export type WindTurbineSchema = z.infer<typeof WindTurbineSchema>;

export const SolarMountingType = z.enum([
  "ground_mounted",
  "canopy",
  "rooftop",
]);
export type SolarMountingType = z.infer<typeof SolarMountingType>;

export const SolarTrackingType = z.enum([
  "fix",
  "single_axis",
  "single_axis_azimuth",
  "dual_axis",
]);
export type SolarTrackingType = z.infer<typeof SolarTrackingType>;

export const OrientationReference = z.enum(["south", "north"]);
export type OrientationReference = z.infer<typeof OrientationReference>;

export const SolarFieldSchema = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  name: z.string(),
  type: SolarMountingType,
  capacity: z.number(),
  tracking: SolarTrackingType,
  tilt: z.number(),
  orientation: z.number(),
  orientation_reference: OrientationReference,
  panel_capacity: z.number().nullish(),
  uuid: z.string().uuid(),
});
export type SolarFieldSchema = z.infer<typeof SolarFieldSchema>;

export const HydroType = z.enum([
  "run_of_river",
  "pumped_storage",
  "reservoir",
]);
export type HydroType = z.infer<typeof HydroType>;

export const HydroTurbineSchema = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  type: HydroType,
  name: z.string(),
  capacity: z.number(),
  uuid: z.string().uuid(),
});
export type HydroTurbineSchema = z.infer<typeof HydroTurbineSchema>;

export const CogeType = z.enum(["biomass", "waste", "other"]);
export type CogeType = z.infer<typeof CogeType>;

export const CogenerationSchema = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  name: z.string(),
  type: CogeType,
  capacity: z.number(),
  uuid: z.string().uuid(),
});
export type CogenerationSchema = z.infer<typeof CogenerationSchema>;

export const AssetSchema = z.union([
  WindTurbineSchema,
  SolarFieldSchema,
  HydroTurbineSchema,
  CogenerationSchema,
]);
export type AssetSchema = z.infer<typeof AssetSchema>;

export const DeliveryPointSchema = z.object({
  code: z.string().nullish(),
  reference: z.string().nullish(),
  network_voltage: NetworkVoltageSchema.nullish(),
  code_kind: DeliveryPointCodeKind.nullish(),
  type: DeliveryPointType.nullish(),
  extra: NetworkCfg.nullish(),
  dso: DSOSchema,
  kind: DeliveryPointKind,
  uuid: z.string().uuid(),
  assets: z.array(AssetSchema),
});
export type DeliveryPointSchema = z.infer<typeof DeliveryPointSchema>;

export const Inverter = z.object({
  brand: z.string().nullish(),
  model: z.string().nullish(),
  capacity: z.number().nullish(),
});
export type Inverter = z.infer<typeof Inverter>;

export const Module = z.object({
  model: z.string().nullish(),
  capacity: z.number().nullish(),
  number: z.number().int().nullish(),
});
export type Module = z.infer<typeof Module>;

export const TechnicalData = z.object({
  inverters: z.array(Inverter).nullish(),
  extra: z.record(z.any()).nullish(),
  modules: z.array(Module).nullish(),
});
export type TechnicalData = z.infer<typeof TechnicalData>;

export const SiteSchema = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.record(z.any()).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.coerce.date().nullish(),
  portfolio: Portfolio.nullish(),
  external_id: z.string().nullish(),
  network_voltage: NetworkVoltageSchema.nullish(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  p50: z.number().nullish(),
  machine_type: z.string(),
  assetowner: CompanySchemaShort,
  status: SiteStatus,
  is_external: z.boolean(),
  estimated_commissioning: z.coerce.date().nullish(),
  deliverypoints: z.array(DeliveryPointSchema),
  metric_status: z.record(z.any()),
  contact_infos: z.array(ContactInfoSchema),
  address: AddresseSchema.nullish(),
  technical_data: TechnicalData.nullish(),
  is_self_consumption: z.boolean(),
  has_operation_data: z.boolean(),
  has_rt_data: z.boolean(),
  has_revenue_data: z.boolean(),
  has_budget_data: z.boolean(),
});
export type SiteSchema = z.infer<typeof SiteSchema>;

export const SiteSchemaUpdate = z.object({
  name: z.string().nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  description: z.record(z.any()).nullish(),
  region: RegionSchema.nullish(),
  country: CountrySchema.nullish(),
  commissioning: z.coerce.date().nullish(),
  portfolio: Portfolio.nullish(),
  external_id: z.string().nullish(),
  technical_data: TechnicalData.nullish(),
});
export type SiteSchemaUpdate = z.infer<typeof SiteSchemaUpdate>;

export const PastillaName = z.enum([
  "power_production",
  "availability",
  "data_health",
  "pr",
]);
export type PastillaName = z.infer<typeof PastillaName>;

export const PastillaStatus = z.enum([
  "missing",
  "missing_configuration",
  "good",
  "warning",
  "error",
  "compute_error",
  "skipped",
  "na",
]);
export type PastillaStatus = z.infer<typeof PastillaStatus>;

export const PastillaSchemaShort = z.object({
  name: PastillaName,
  status: PastillaStatus,
  gauge_value: z.number().nullish(),
  budget_value: z.number().nullish(),
  last_update: z.coerce.date(),
  last_status_change: z.coerce.date(),
  comment: z.string().nullish(),
});
export type PastillaSchemaShort = z.infer<typeof PastillaSchemaShort>;

export const SiteContractStatus = z.enum([
  "market_access",
  "feed_in_tarif",
  "contract_for_difference",
  "ppa",
  "lease",
  "maintenance",
  "new_project",
  "not_contracted",
]);
export type SiteContractStatus = z.infer<typeof SiteContractStatus>;

export const SiteSchemaOverview = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.record(z.any()).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.coerce.date().nullish(),
  portfolio: Portfolio.nullish(),
  external_id: z.string().nullish(),
  network_voltage: NetworkVoltageSchema.nullish(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  p50: z.number().nullish(),
  machine_type: z.string(),
  assetowner: CompanySchemaShort,
  status: SiteStatus,
  is_external: z.boolean(),
  estimated_commissioning: z.coerce.date().nullish(),
  metric_status: z.record(z.any()),
  cached_pastilla_values: z.record(PastillaSchemaShort),
  contract_status: z.array(SiteContractStatus),
  dp_codes: z.array(z.string()),
  dp_references: z.array(z.string()),
  data_origins: z.array(z.string()),
});
export type SiteSchemaOverview = z.infer<typeof SiteSchemaOverview>;

export const WindTurbineSchemaCreate = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  hub_height: z.number().nullish(),
  name: z.string(),
  model: z.string(),
  manufacturer: z.string(),
});
export type WindTurbineSchemaCreate = z.infer<typeof WindTurbineSchemaCreate>;

export const SolarFieldSchemaCreate = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  name: z.string(),
  type: SolarMountingType,
  capacity: z.number(),
  tracking: SolarTrackingType,
  tilt: z.number(),
  orientation: z.number(),
  orientation_reference: OrientationReference,
  panel_capacity: z.number().nullish(),
});
export type SolarFieldSchemaCreate = z.infer<typeof SolarFieldSchemaCreate>;

export const HydroTurbineSchemaCreate = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  type: HydroType,
  name: z.string(),
  capacity: z.number(),
});
export type HydroTurbineSchemaCreate = z.infer<typeof HydroTurbineSchemaCreate>;

export const DeliveryPointSchemaCreate = z.object({
  code: z.string().nullish(),
  reference: z.string().nullish(),
  network_voltage: NetworkVoltageSchema.nullish(),
  code_kind: DeliveryPointCodeKind,
  type: DeliveryPointType,
  extra: NetworkCfg.nullish(),
  dso: DSOSchema,
  assets: z.array(
    z.union([
      WindTurbineSchemaCreate,
      SolarFieldSchemaCreate,
      HydroTurbineSchemaCreate,
    ])
  ),
});
export type DeliveryPointSchemaCreate = z.infer<
  typeof DeliveryPointSchemaCreate
>;

export const SiteSchemaCreate = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.record(z.any()).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.coerce.date().nullish(),
  portfolio: Portfolio.nullish(),
  external_id: z.string().nullish(),
  network_voltage: NetworkVoltageSchema.nullish(),
  assetowner_uuid: z.string().uuid().nullish(),
  deliverypoints: z.array(DeliveryPointSchemaCreate).nullish().default([]),
});
export type SiteSchemaCreate = z.infer<typeof SiteSchemaCreate>;

export const IOFormatDF = z.enum(["json", "feather"]);
export type IOFormatDF = z.infer<typeof IOFormatDF>;

export const InvoiceStatus = z.enum([
  "error",
  "incomplete",
  "computed",
  "capped",
  "informative",
  "draft",
  "published",
  "validated",
  "waiting",
  "submitting",
  "submitted",
  "payment",
  "rejected",
  "production_null",
  "canceled_by_credit_note",
  "to_validate",
  "to_redo",
  "paid",
  "deleted",
]);
export type InvoiceStatus = z.infer<typeof InvoiceStatus>;

export const InvoiceSiteSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  external_id: z.string().nullish(),
  dp_codes: z.array(z.string()),
  is_external: z.boolean(),
  assetowner: CompanySchemaShort,
  capacity: z.number(),
  portfolio: Portfolio.nullish(),
});
export type InvoiceSiteSchema = z.infer<typeof InvoiceSiteSchema>;

export const PriceMechanism = z.enum([
  "market_access",
  "feed_in_tarif",
  "contract_for_difference",
  "ppa",
  "lease",
  "maintenance",
]);
export type PriceMechanism = z.infer<typeof PriceMechanism>;

export const ContractTypeSchema = z.object({
  name: z.string(),
  price_mechanism: PriceMechanism,
});
export type ContractTypeSchema = z.infer<typeof ContractTypeSchema>;

export const InvoiceSubmitChannel = z.enum([
  "email",
  "platform",
  "cegedim",
  "mail",
]);
export type InvoiceSubmitChannel = z.infer<typeof InvoiceSubmitChannel>;

export const InvoiceContractSchema = z.object({
  uuid: z.string().uuid(),
  purchase_order: z.string().nullish(),
  type: ContractTypeSchema,
  offtaker: CompanySchemaShort,
  invoice_submit_channel: InvoiceSubmitChannel.nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
});
export type InvoiceContractSchema = z.infer<typeof InvoiceContractSchema>;

export const InvoiceExternalStatus = z.enum([
  "matched",
  "energy_mismatch",
  "price_mismatch",
  "energy_and_price_mismatch",
  "to_be_matched",
]);
export type InvoiceExternalStatus = z.infer<typeof InvoiceExternalStatus>;

export const ClauseSchemaShort = z.object({
  uuid: z.string().uuid(),
  type: z.string(),
});
export type ClauseSchemaShort = z.infer<typeof ClauseSchemaShort>;

export const SiteInvoiceLine = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
});
export type SiteInvoiceLine = z.infer<typeof SiteInvoiceLine>;

export const InvoiceLineSchema = z.object({
  product: z.string(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  quantity: z.number(),
  price: z.number(),
  amount: z.number(),
  quantity_unit: z.string(),
  is_external: z.boolean(),
  is_summable: z.boolean(),
  clause: ClauseSchemaShort,
  site: SiteInvoiceLine.nullish(),
});
export type InvoiceLineSchema = z.infer<typeof InvoiceLineSchema>;

export const InvoiceLogAction = z.object({
  date: z.coerce.date(),
  status: InvoiceStatus.nullish(),
  action: z.string().nullish(),
  initiator: z.string().nullish(),
});
export type InvoiceLogAction = z.infer<typeof InvoiceLogAction>;

export const InseeIndex = z.enum(["FM0ABE", "ICHT"]);
export type InseeIndex = z.infer<typeof InseeIndex>;

export const InseeIndexComputationDetails = z.object({
  name: InseeIndex,
  coef: z.number(),
  rebased: z.number(),
  rebase_coefs: z.record(z.number()),
  fraction: z.number(),
  latest_value: z.number(),
  initial_value: z.number(),
  base_year: z.number().int().nullish(),
});
export type InseeIndexComputationDetails = z.infer<
  typeof InseeIndexComputationDetails
>;

export const LIndexComputationDetails = z.object({
  version: z.coerce.date(),
  L: z.number(),
  map_name_indexes: z.record(InseeIndexComputationDetails),
});
export type LIndexComputationDetails = z.infer<typeof LIndexComputationDetails>;

export const InvoiceCoefDetails = z.object({
  L_details: LIndexComputationDetails.nullish(),
  indexed_tarif: z.number().nullish(),
  M0: z.number().nullish(),
  credit_note_cap: z.number().nullish(),
  cumulated_deferment: z.number().nullish(),
});
export type InvoiceCoefDetails = z.infer<typeof InvoiceCoefDetails>;

export const PPAPriceType = z.enum(["fix_price", "epex_spot"]);
export type PPAPriceType = z.infer<typeof PPAPriceType>;

export const TarifType = z.object({
  price_type: PPAPriceType,
  fix_price: z.number().int().nullish(),
  management_fee: z.number().int().nullish(),
});
export type TarifType = z.infer<typeof TarifType>;

export const _HydroTarifBase = z.object({
  tarif: z.number(),
  majoration: z.number().nullish(),
  uncovered_tarif: z.record(z.any()).nullish(),
});
export type _HydroTarifBase = z.infer<typeof _HydroTarifBase>;

export const HydroTarifComponents = z.object({
  winter_base: _HydroTarifBase.nullish(),
  winter_off_peak: _HydroTarifBase.nullish(),
  winter_peak: _HydroTarifBase.nullish(),
  summer_base: _HydroTarifBase.nullish(),
  summer_off_peak: _HydroTarifBase.nullish(),
  summer_peak: _HydroTarifBase.nullish(),
  base_peak: _HydroTarifBase.nullish(),
  base_off_peak: _HydroTarifBase.nullish(),
});
export type HydroTarifComponents = z.infer<typeof HydroTarifComponents>;

export const InvoiceClauseDetails = z.object({
  tarif: z.number().nullish(),
  energy_threshold: z.number().nullish(),
  tarif_above_threshold: z.number().nullish(),
  fix_price: z.number().nullish(),
  management_fee: z.number().nullish(),
  management_coef: z.number().nullish(),
  majoration: z.number().nullish(),
  management_bonus: z.number().nullish(),
  formula: z.string().nullish(),
  power_threshold: z.number().nullish(),
  below_tarif: z.union([z.number(), TarifType]).nullish(),
  above_tarif: z.union([z.number(), TarifType]).nullish(),
  tarif_components: HydroTarifComponents.nullish(),
  quality_majoration: z.number().nullish(),
  quality_coefficient: z.number().nullish(),
  deliverypoint_references: z.array(z.string()).nullish(),
  pae: z.number().nullish(),
  spot_prices_coefficient: z.number().nullish(),
  premium_autoconso: z.number().int().nullish(),
  cumulated_actualisation_rate: z.number().nullish(),
  contractual_amount: z.number().int().nullish(),
  actualised_amount: z.number().int().nullish(),
  autoconsumption_rate: z.number().nullish(),
  vat_amount: z.number().int().nullish(),
  price: z.number().nullish(),
  number_of_spot_negative_hours: z.number().nullish(),
  number_of_spot_negative_hours_with_production: z.number().nullish(),
  penalty_way_coefficient: z.number().nullish(),
  injection_penalty: z.number().nullish(),
  max_injected_capa_value: z.number().nullish(),
  effluents_tarif: z.number().nullish(),
  effluents_rate: z.number().nullish(),
  effluents_rate_threshold: z.number().nullish(),
  ft_cost: z.number().nullish(),
  discount_rate: z.number().nullish(),
  comparison_amount: z.number().nullish(),
  positive_imbalance_coef: z.number().nullish(),
  negative_imbalance_coef: z.number().nullish(),
});
export type InvoiceClauseDetails = z.infer<typeof InvoiceClauseDetails>;

export const InvoiceIndex = z.object({
  start_value: z.number().int().nullish(),
  end_value: z.number().int().nullish(),
  value: z.number().int().nullish(),
});
export type InvoiceIndex = z.infer<typeof InvoiceIndex>;

export const InvoiceExtraDetails = z.object({
  prod_index: InvoiceIndex.nullish().default({}),
  conso_index: InvoiceIndex.nullish().default({}),
  conso: z.number().int().nullish(),
  prod: z.number().int().nullish(),
});
export type InvoiceExtraDetails = z.infer<typeof InvoiceExtraDetails>;

export const ClauseTypes = z.enum([
  "cfd_cr_16",
  "cfd_cr_17",
  "cfd_majoration",
  "cfd_autoconso",
  "ft_wind_monthly",
  "ft_solar_monthly",
  "ft_solar_semestrial",
  "ft_hydro_monthly",
  "ft_biogas_monthly",
  "market_access",
  "market_access_imb",
  "ppa_da",
  "ppa_fix",
  "ppa_threshold",
  "ppa_tarif_components",
  "ppa_threshold_multi_period",
  "ppa_management_fee",
  "ppa_da_indexed_fee",
  "ppa_imbalance_index_fee",
  "undelivered_energy_penalty",
  "ppa_asia",
  "neg_price",
  "spot_management_fee",
  "goo",
  "gc",
  "credit_note_cap",
  "settlement",
  "autoconso",
  "autoconso_master",
  "energy_guarantee",
  "energy_guarantee_risk_premium",
  "maintenance",
]);
export type ClauseTypes = z.infer<typeof ClauseTypes>;

export const ClauseComputationResultDetails = z.object({
  coef: InvoiceCoefDetails.nullish(),
  clause: InvoiceClauseDetails.nullish(),
  extra: InvoiceExtraDetails.nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  clause_type: ClauseTypes,
});
export type ClauseComputationResultDetails = z.infer<
  typeof ClauseComputationResultDetails
>;

export const InvoiceSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  computable_asof: z.coerce.date(),
  publication_date: z.coerce.date().nullish(),
  computation_date: z.coerce.date().nullish(),
  publication_id: z.string().nullish(),
  status: InvoiceStatus,
  comment: z.string().nullish(),
  site: InvoiceSiteSchema,
  contract: InvoiceContractSchema,
  is_birthday: z.boolean(),
  is_accounted: z.boolean(),
  accounted_date: z.coerce.date().nullish(),
  accounted_initiator: z.string().nullish(),
  external_status: InvoiceExternalStatus.nullish(),
  has_pdf: z.boolean(),
  lines: z.array(InvoiceLineSchema),
  external_lines: z.array(InvoiceLineSchema),
  log_actions: z.array(InvoiceLogAction),
  details: z.array(ClauseComputationResultDetails),
});
export type InvoiceSchema = z.infer<typeof InvoiceSchema>;

export const InvoiceSchemaUpdate = z.object({
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  comment: z.string().nullish(),
});
export type InvoiceSchemaUpdate = z.infer<typeof InvoiceSchemaUpdate>;

export const InvoiceSchemaShort = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  computable_asof: z.coerce.date(),
  publication_date: z.coerce.date().nullish(),
  computation_date: z.coerce.date().nullish(),
  publication_id: z.string().nullish(),
  status: InvoiceStatus,
  comment: z.string().nullish(),
  site: InvoiceSiteSchema,
  contract: InvoiceContractSchema,
  is_birthday: z.boolean(),
  is_accounted: z.boolean(),
  accounted_date: z.coerce.date().nullish(),
  accounted_initiator: z.string().nullish(),
  external_status: InvoiceExternalStatus.nullish(),
  has_pdf: z.boolean(),
  lines: z.array(InvoiceLineSchema),
  external_lines: z.array(InvoiceLineSchema),
});
export type InvoiceSchemaShort = z.infer<typeof InvoiceSchemaShort>;

export const InvoiceSchemaSync = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  computable_asof: z.coerce.date(),
  publication_date: z.coerce.date().nullish(),
  computation_date: z.coerce.date().nullish(),
  publication_id: z.string(),
  status: InvoiceStatus,
  comment: z.string().nullish(),
  site: InvoiceSiteSchema,
  contract: InvoiceContractSchema,
  is_birthday: z.boolean(),
  is_accounted: z.boolean(),
  accounted_date: z.coerce.date().nullish(),
  accounted_initiator: z.string().nullish(),
  external_status: InvoiceExternalStatus.nullish(),
  has_pdf: z.boolean(),
  lines: z.array(InvoiceLineSchema),
  external_lines: z.array(InvoiceLineSchema),
  log_actions: z.array(InvoiceLogAction),
});
export type InvoiceSchemaSync = z.infer<typeof InvoiceSchemaSync>;

export const Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post =
  z.object({
    start: z.coerce.date().nullish(),
    end: z.coerce.date().nullish(),
    regulation_only: z.boolean().nullish(),
    flt_on_valuation_horizon: z.boolean().nullish().default(true),
  });
export type Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post =
  z.infer<
    typeof Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post
  >;

export const InvoiceComputationSchema = z.object({
  new: z.array(InvoiceSchema).nullish(),
  refreshed: z.array(InvoiceSchema).nullish(),
  published: z.array(InvoiceSchema).nullish(),
});
export type InvoiceComputationSchema = z.infer<typeof InvoiceComputationSchema>;

export const Body_submit_invoice_from_model_api__model___uuid__invoices_submit_post =
  z.object({
    start: z.coerce.date().nullish(),
    end: z.coerce.date().nullish(),
  });
export type Body_submit_invoice_from_model_api__model___uuid__invoices_submit_post =
  z.infer<
    typeof Body_submit_invoice_from_model_api__model___uuid__invoices_submit_post
  >;

export const Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post =
  z.object({
    start: z.coerce.date().nullish(),
    end: z.coerce.date().nullish(),
    as_draft: z.boolean().nullish(),
  });
export type Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post =
  z.infer<
    typeof Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post
  >;

export const InvoiceCancelActions = z.enum([
  "unpublish",
  "reject",
  "void_and_replace",
  "credit_note",
  "delete",
]);
export type InvoiceCancelActions = z.infer<typeof InvoiceCancelActions>;

export const Body_set_invoice_accounted_api_invoices__uuid__accounted_post =
  z.object({ is_accounted: z.boolean().nullish().default(true) });
export type Body_set_invoice_accounted_api_invoices__uuid__accounted_post =
  z.infer<typeof Body_set_invoice_accounted_api_invoices__uuid__accounted_post>;

export const OfftakerSchema = z.object({
  name: z.string(),
  image_link: z.string().nullish(),
  url: z.string().nullish(),
});
export type OfftakerSchema = z.infer<typeof OfftakerSchema>;

export const InvoiceSource = z.enum(["Renewex", "Offtaker", "Asset Owner"]);
export type InvoiceSource = z.infer<typeof InvoiceSource>;

export const SiteSchemaShort = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.record(z.any()).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.coerce.date().nullish(),
  portfolio: Portfolio.nullish(),
  external_id: z.string().nullish(),
  network_voltage: NetworkVoltageSchema.nullish(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  p50: z.number().nullish(),
  machine_type: z.string(),
  assetowner: CompanySchemaShort,
  status: SiteStatus,
  is_external: z.boolean(),
  estimated_commissioning: z.coerce.date().nullish(),
});
export type SiteSchemaShort = z.infer<typeof SiteSchemaShort>;

export const LIndexVersionDesc = z.enum(["AS-JAN", "AS-NOV", "AS-BIRTHDAY"]);
export type LIndexVersionDesc = z.infer<typeof LIndexVersionDesc>;

export const IndexCoefficient = z.object({
  index: InseeIndex,
  coefficient: z.number().nullish(),
});
export type IndexCoefficient = z.infer<typeof IndexCoefficient>;

export const LIndexCfg = z.object({
  month_base: LIndexVersionDesc,
  on_birthday: z.boolean(),
  defaults: z.array(IndexCoefficient).nullish(),
});
export type LIndexCfg = z.infer<typeof LIndexCfg>;

export const IndexationParameters = z.object({
  index: InseeIndex,
  coefficient: z.number().nullish(),
  initial_value: z.number(),
  base_year: z.number().int().nullish(),
});
export type IndexationParameters = z.infer<typeof IndexationParameters>;

export const EnergyParametersOptFields = z.object({
  freq: z.string().nullish(),
  shift_hours: z.number().int().nullish(),
  cap_to_capa: z.boolean().nullish(),
  exclude_neg_volumes: z.boolean().nullish(),
  use_index: z.boolean().nullish(),
  round_energy_before_amount: z.boolean().nullish(),
  best_of_tags: z
    .array(z.union([z.enum(["R4Q", "R4M", "R172"]), z.array(z.string())]))
    .nullish(),
});
export type EnergyParametersOptFields = z.infer<
  typeof EnergyParametersOptFields
>;

export const InvoicingFreq = z.enum([
  "semestrial",
  "monthly",
  "yearly",
  "quarterly",
]);
export type InvoicingFreq = z.infer<typeof InvoicingFreq>;

export const InvoicingCfgDetails = z.object({
  freq: InvoicingFreq,
  is_freq_rounded: z.boolean(),
  delay: z.array(z.string()).nullish().default([]),
  delay_reference: z.enum(["start", "end"]).nullish().default("end"),
  grouping_key_suffix: z.string().nullish(),
  allow_overlapping_horizons: z.boolean().nullish(),
});
export type InvoicingCfgDetails = z.infer<typeof InvoicingCfgDetails>;

export const InvoicingCfg = z.object({
  base: InvoicingCfgDetails,
  regulation: InvoicingCfgDetails.nullish(),
  tarif_change_on_birthday: z.boolean().nullish(),
});
export type InvoicingCfg = z.infer<typeof InvoicingCfg>;

export const CfdCr16 = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("cfd_cr_16"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number(),
  contracted_capacity: z.number().nullish(),
  management_bonus: z.number(),
});
export type CfdCr16 = z.infer<typeof CfdCr16>;

export const CfdCr17 = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("cfd_cr_17"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number(),
  contracted_capacity: z.number().nullish(),
  management_bonus: z.number(),
  energy_threshold: z.number(),
  tarif_above_threshold: z.number().nullish().default(40),
});
export type CfdCr17 = z.infer<typeof CfdCr17>;

export const CfdMajoration = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("cfd_majoration"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number(),
  contracted_capacity: z.number().nullish(),
  majoration: z.number(),
});
export type CfdMajoration = z.infer<typeof CfdMajoration>;

export const FeedInTarifWindMonthly = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_wind_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  tarif: z.number(),
});
export type FeedInTarifWindMonthly = z.infer<typeof FeedInTarifWindMonthly>;

export const FeedInTarifSolarMonthly = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_solar_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish().default({
    base: {
      freq: "monthly",
      is_freq_rounded: true,
      delay: ["3D"],
      delay_reference: "end",
      allow_overlapping_horizons: false,
    },
    tarif_change_on_birthday: false,
  }),
  contracted_capacity: z.number().nullish(),
  tarif: z.number(),
  majoration: z.number().nullish(),
  energy_threshold: z.number(),
  tarif_above_threshold: z.number().nullish().default(50),
  apply_energy_threshold: z.boolean().nullish(),
  use_index: z.boolean().nullish().default(true),
  use_index_extrapolation: z.boolean().nullish().default(true),
  production_prorata_coef: z.number().nullish(),
  use_prorata_temporis: z.boolean().nullish().default(true),
});
export type FeedInTarifSolarMonthly = z.infer<typeof FeedInTarifSolarMonthly>;

export const FeedInTarifSolarSemestrial = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_solar_semestrial"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  tarif: z.number(),
  majoration: z.number().nullish(),
  energy_threshold: z.number(),
  tarif_above_threshold: z.number().nullish().default(50),
  apply_energy_threshold: z.boolean().nullish(),
  use_index: z.boolean().nullish().default(true),
  use_index_extrapolation: z.boolean().nullish().default(true),
  production_prorata_coef: z.number().nullish(),
});
export type FeedInTarifSolarSemestrial = z.infer<
  typeof FeedInTarifSolarSemestrial
>;

export const SeasonEnum = z.enum(["base", "winter", "summer"]);
export type SeasonEnum = z.infer<typeof SeasonEnum>;

export const HourPeriod = z.enum(["base", "off_peak", "peak", "super_peak"]);
export type HourPeriod = z.infer<typeof HourPeriod>;

export const PublicHoliday = z.object({
  day: z.number().int(),
  month: z.number().int(),
});
export type PublicHoliday = z.infer<typeof PublicHoliday>;

export const PeriodDefinition = z.object({
  months: z
    .array(z.number())
    .nullish()
    .default([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  weekdays: z.array(z.number()).nullish().default([0, 1, 2, 3, 4, 5, 6]),
  hours: z
    .array(z.number())
    .nullish()
    .default([
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ]),
  days_to_exclude: z.array(PublicHoliday).nullish().default([]),
  days_to_include: z.array(PublicHoliday).nullish().default([]),
});
export type PeriodDefinition = z.infer<typeof PeriodDefinition>;

export const PpaTarifComponent = z.object({
  price_type: PPAPriceType,
  fix_price: z.number().nullish(),
  management_fee: z.number().nullish(),
  management_coef: z.number().nullish().default(1),
});
export type PpaTarifComponent = z.infer<typeof PpaTarifComponent>;

export const TarifComponent = z.object({
  tarif: z.number(),
  majoration: z.number().nullish(),
  season: SeasonEnum,
  hour_period: HourPeriod,
  period_definitions: z.array(PeriodDefinition),
  power_threshold: z.number().nullish(),
  uncovered_tarif: PpaTarifComponent.nullish(),
});
export type TarifComponent = z.infer<typeof TarifComponent>;

export const FeedInTarifHydro = z.object({
  tarif_components: z.array(TarifComponent),
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_hydro_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  quality_majoration: z.number(),
  quality_coefficient: z.number(),
  prorata: z.number().nullish().default(1),
  shift_hours: z.number().int().nullish().default(2),
});
export type FeedInTarifHydro = z.infer<typeof FeedInTarifHydro>;

export const NegativeHoursSource = z.enum(["market", "cre"]);
export type NegativeHoursSource = z.infer<typeof NegativeHoursSource>;

export const IndexPriceFallback = z.enum([
  "offtaker",
  "renewex",
  "assetmanager",
  "site",
]);
export type IndexPriceFallback = z.infer<typeof IndexPriceFallback>;

export const MarketAccess = z.object({
  type: z.literal("market_access"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  management_fee_coefficient: z.number().nullish(),
  exclude_neg_price_hours: z.boolean().nullish(),
  neg_prices_source: NegativeHoursSource.nullish().default("market"),
  index_price_fallback: IndexPriceFallback.nullish().default("offtaker"),
  single_line: z.boolean().nullish(),
});
export type MarketAccess = z.infer<typeof MarketAccess>;

export const SpotManagementFee = z.object({
  type: z.literal("spot_management_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  formula: z.string(),
  min_value: z.number().nullish(),
  max_value: z.number().nullish(),
});
export type SpotManagementFee = z.infer<typeof SpotManagementFee>;

export const MarketAccessImbalance = z.object({
  type: z.literal("market_access_imb"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
});
export type MarketAccessImbalance = z.infer<typeof MarketAccessImbalance>;

export const PpaDa = z.object({
  type: z.literal("ppa_da"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  management_coef: z.number().nullish().default(1),
});
export type PpaDa = z.infer<typeof PpaDa>;

export const PpaDaIndexedFee = z.object({
  type: z.literal("ppa_da_indexed_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  management_coef: z.number().nullish().default(1),
  prorata: z.number().nullish().default(1),
});
export type PpaDaIndexedFee = z.infer<typeof PpaDaIndexedFee>;

export const PPAImbalanceIndexedFee = z.object({
  type: z.literal("ppa_imbalance_index_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  positive_imbalance_coef: z.number().nullish().default(1),
  negative_imbalance_coef: z.number().nullish().default(1),
});
export type PPAImbalanceIndexedFee = z.infer<typeof PPAImbalanceIndexedFee>;

export const PpaFix = z.object({
  type: z.literal("ppa_fix"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  fix_price: z.number(),
  management_fee: z.number().nullish(),
  under_positive_spot_prices: z.boolean().nullish().default(true),
});
export type PpaFix = z.infer<typeof PpaFix>;

export const PpaManagementFee = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ppa_management_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  under_positive_spot_prices: z.boolean().nullish().default(true),
});
export type PpaManagementFee = z.infer<typeof PpaManagementFee>;

export const PpaThreshold = z.object({
  type: z.literal("ppa_threshold"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif_below_cap: PpaTarifComponent,
  tarif_above_cap: PpaTarifComponent,
  power_threshold: z.number(),
  management_fee: z.number().nullish(),
});
export type PpaThreshold = z.infer<typeof PpaThreshold>;

export const PpaMultiTarif = z.object({
  tarif_components: z.array(TarifComponent),
  type: z.literal("ppa_tarif_components"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
});
export type PpaMultiTarif = z.infer<typeof PpaMultiTarif>;

export const PriceType = z.enum(["spot", "imbalance"]);
export type PriceType = z.infer<typeof PriceType>;

export const NegativePrice = z.object({
  type: z.literal("neg_price"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  price_type: PriceType,
  use_absolute_value: z.boolean().nullish(),
  management_fee: z.number().nullish(),
  penalty_way_coefficient: z.number().nullish().default(1),
});
export type NegativePrice = z.infer<typeof NegativePrice>;

export const DefermentRecord = z.object({
  value: z.number(),
  date: z.coerce.date(),
});
export type DefermentRecord = z.infer<typeof DefermentRecord>;

export const CreditNoteCap = z.object({
  type: z.literal("credit_note_cap"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  with_deferment: z.boolean().nullish().default(true),
  statuses: z
    .array(InvoiceStatus)
    .nullish()
    .default(["published", "paid", "computed", "submitted", "capped"]),
  known_deferment: z.array(DefermentRecord).nullish().default([]),
});
export type CreditNoteCap = z.infer<typeof CreditNoteCap>;

export const Goo = z.object({
  type: z.literal("goo"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  price: z.number(),
});
export type Goo = z.infer<typeof Goo>;

export const MaxCapacityRecord = z.object({
  value: z.number(),
  year: z.number().int(),
});
export type MaxCapacityRecord = z.infer<typeof MaxCapacityRecord>;

export const CfdAutoconso = z.object({
  type: z.literal("cfd_autoconso"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number(),
  premium_autoconso: z.number().nullish().default(10),
  yearly_max_capacity: z.array(MaxCapacityRecord).nullish(),
});
export type CfdAutoconso = z.infer<typeof CfdAutoconso>;

export const BonusMalusRecord = z.object({
  value: z.number(),
  year: z.number().int(),
  recovery_date: z.coerce.date().nullish(),
});
export type BonusMalusRecord = z.infer<typeof BonusMalusRecord>;

export const DateOp = z.enum(["add", "ceil", "floor"]);
export type DateOp = z.infer<typeof DateOp>;

export const ChainDateOpRecord = z.object({ op: DateOp, freq: z.string() });
export type ChainDateOpRecord = z.infer<typeof ChainDateOpRecord>;

export const Autoconso = z.object({
  type: z.literal("autoconso"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  yearly_tarif: z.number(),
  yearly_actualisation_rate: z.number(),
  bonus_malus: z.array(BonusMalusRecord).nullish(),
  payment_delay_ops: z.array(ChainDateOpRecord).nullish(),
  prorata_temporis_mode: z
    .enum(["timedelta", "ndays"])
    .nullish()
    .default("ndays"),
});
export type Autoconso = z.infer<typeof Autoconso>;

export const AutoconsoMaster = z.object({
  type: z.literal("autoconso_master"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
});
export type AutoconsoMaster = z.infer<typeof AutoconsoMaster>;

export const Settlement = z.object({
  type: z.literal("settlement"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  price: z.number().nullish().default(1),
});
export type Settlement = z.infer<typeof Settlement>;

export const UndeliveredEnergyPenalty = z.object({
  type: z.literal("undelivered_energy_penalty"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  pae: z.number(),
  spot_prices_coefficient: z.number(),
});
export type UndeliveredEnergyPenalty = z.infer<typeof UndeliveredEnergyPenalty>;

export const FeedInTarifBiogas = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_biogas_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  tarif: z.number(),
  shift_hours: z.number().int().nullish(),
  use_index: z.boolean().nullish().default(true),
  effluents_rate: z.number().nullish(),
  effluents_rate_threshold: z.number().nullish().default(0.6),
  effluents_tarif: z.number().nullish().default(50),
});
export type FeedInTarifBiogas = z.infer<typeof FeedInTarifBiogas>;

export const PpaThresholdMultiPeriod = z.object({
  tarif_components: z.array(TarifComponent),
  type: z.literal("ppa_threshold_multi_period"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  epex_fee: z.number().nullish().default(0.07),
});
export type PpaThresholdMultiPeriod = z.infer<typeof PpaThresholdMultiPeriod>;

export const EnergyGuarantee = z.object({
  type: z.literal("energy_guarantee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  fix_price: z.number(),
  management_fee: z.number().nullish(),
  under_positive_spot_prices: z.boolean().nullish().default(true),
  yearly_cashflow: z.array(z.number()),
  monthly_seasonality: z
    .array(z.number())
    .nullish()
    .default([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]),
});
export type EnergyGuarantee = z.infer<typeof EnergyGuarantee>;

export const EnergyGuaranteeRiskPremium = z.object({
  type: z.literal("energy_guarantee_risk_premium"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  guarantee_percentage: z.number(),
});
export type EnergyGuaranteeRiskPremium = z.infer<
  typeof EnergyGuaranteeRiskPremium
>;

export const PpaAsia = z.object({
  tarif_components: z.array(TarifComponent),
  type: z.literal("ppa_asia"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  ft_cost: z.number().nullish(),
  discount_rate: z.number().nullish(),
});
export type PpaAsia = z.infer<typeof PpaAsia>;

export const GCRecord = z.object({
  year: z.number().int(),
  capacity: z.number(),
  price: z.number(),
});
export type GCRecord = z.infer<typeof GCRecord>;

export const GuaranteeOfCapacity = z.object({
  type: z.literal("gc"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  records: z.array(GCRecord).nullish(),
  certification_fee: z.number().nullish(),
  management_fee: z.number().nullish(),
});
export type GuaranteeOfCapacity = z.infer<typeof GuaranteeOfCapacity>;

export const Maintenance = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("maintenance"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number(),
});
export type Maintenance = z.infer<typeof Maintenance>;

export const ClauseTypeModel = z.discriminatedUnion("type", [
  CfdCr16,
  CfdCr17,
  CfdMajoration,
  FeedInTarifWindMonthly,
  FeedInTarifSolarMonthly,
  FeedInTarifSolarSemestrial,
  FeedInTarifHydro,
  MarketAccess,
  SpotManagementFee,
  MarketAccessImbalance,
  PpaDa,
  PpaDaIndexedFee,
  PPAImbalanceIndexedFee,
  PpaFix,
  PpaManagementFee,
  PpaThreshold,
  PpaMultiTarif,
  NegativePrice,
  CreditNoteCap,
  Goo,
  CfdAutoconso,
  Autoconso,
  AutoconsoMaster,
  Settlement,
  UndeliveredEnergyPenalty,
  FeedInTarifBiogas,
  PpaThresholdMultiPeriod,
  EnergyGuarantee,
  EnergyGuaranteeRiskPremium,
  PpaAsia,
  GuaranteeOfCapacity,
  Maintenance,
]);

export type ClauseTypeModel = z.infer<typeof ClauseTypeModel>;

export const ClauseSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  type: ClauseTypes,
  parameters: ClauseTypeModel,
  pre_computed: z.array(LIndexComputationDetails).nullish(),
});
export type ClauseSchema = z.infer<typeof ClauseSchema>;

export const ContractStatusHistorySchema = z.object({
  date: z.coerce.date(),
  status: ContractStatus,
});
export type ContractStatusHistorySchema = z.infer<
  typeof ContractStatusHistorySchema
>;

export const ContractSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  offtaker: OfftakerSchema,
  type: ContractTypeSchema,
  current_status: ContractStatus,
  price_mechanism: z.enum([
    "market_access",
    "feed_in_tarif",
    "contract_for_difference",
    "ppa",
    "lease",
    "maintenance",
  ]),
  invoice_source: InvoiceSource,
  invoice_submit_channel: InvoiceSubmitChannel.nullish(),
  invoicing_automation_start: z.coerce.date().nullish(),
  site: SiteSchemaShort,
  target_assetowner: z.string().nullish(),
  external_id: z.string().nullish(),
  external_name: z.string().nullish(),
  purchase_order: z.string().nullish(),
  order_number: z.string().nullish(),
  market_number: z.string().nullish(),
  clauses: z.array(ClauseSchema),
  statuses: z.array(ContractStatusHistorySchema),
  invoicing_cfg: InvoicingCfg,
});
export type ContractSchema = z.infer<typeof ContractSchema>;

export const ContractSchemaUpdate = z.object({
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  type: ContractTypeSchema.nullish(),
  invoice_source: InvoiceSource.nullish(),
  purchase_order: z.string().nullish(),
  invoicing_automation_start: z.coerce.date().nullish(),
  invoice_submit_channel: InvoiceSubmitChannel.nullish(),
  external_id: z.string().nullish(),
  external_name: z.string().nullish(),
  order_number: z.string().nullish(),
  market_number: z.string().nullish(),
  target_assetowner: z.string().nullish(),
});
export type ContractSchemaUpdate = z.infer<typeof ContractSchemaUpdate>;

export const BaseContractSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  offtaker: OfftakerSchema,
  type: ContractTypeSchema,
  current_status: ContractStatus,
  price_mechanism: z.enum([
    "market_access",
    "feed_in_tarif",
    "contract_for_difference",
    "ppa",
    "lease",
    "maintenance",
  ]),
  invoice_source: InvoiceSource,
  invoice_submit_channel: InvoiceSubmitChannel.nullish(),
  invoicing_automation_start: z.coerce.date().nullish(),
  site: SiteSchemaShort,
  target_assetowner: z.string().nullish(),
  external_id: z.string().nullish(),
  external_name: z.string().nullish(),
  purchase_order: z.string().nullish(),
  order_number: z.string().nullish(),
  market_number: z.string().nullish(),
});
export type BaseContractSchema = z.infer<typeof BaseContractSchema>;

export const CfdCr16OptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("cfd_cr_16"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number().nullish(),
  contracted_capacity: z.number().nullish(),
  management_bonus: z.number().nullish(),
});
export type CfdCr16OptFields = z.infer<typeof CfdCr16OptFields>;

export const CfdCr17OptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("cfd_cr_17"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number().nullish(),
  contracted_capacity: z.number().nullish(),
  management_bonus: z.number().nullish(),
  energy_threshold: z.number().nullish(),
  tarif_above_threshold: z.number().nullish(),
});
export type CfdCr17OptFields = z.infer<typeof CfdCr17OptFields>;

export const CfdMajorationOptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("cfd_majoration"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number().nullish(),
  contracted_capacity: z.number().nullish(),
  majoration: z.number().nullish(),
});
export type CfdMajorationOptFields = z.infer<typeof CfdMajorationOptFields>;

export const FeedInTarifWindMonthlyOptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_wind_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  tarif: z.number().nullish(),
});
export type FeedInTarifWindMonthlyOptFields = z.infer<
  typeof FeedInTarifWindMonthlyOptFields
>;

export const FeedInTarifSolarMonthlyOptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_solar_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  tarif: z.number().nullish(),
  majoration: z.number().nullish(),
  energy_threshold: z.number().nullish(),
  tarif_above_threshold: z.number().nullish(),
  apply_energy_threshold: z.boolean().nullish(),
  use_index: z.boolean().nullish(),
  use_index_extrapolation: z.boolean().nullish(),
  production_prorata_coef: z.number().nullish(),
  use_prorata_temporis: z.boolean().nullish(),
});
export type FeedInTarifSolarMonthlyOptFields = z.infer<
  typeof FeedInTarifSolarMonthlyOptFields
>;

export const FeedInTarifSolarSemestrialOptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_solar_semestrial"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  tarif: z.number().nullish(),
  majoration: z.number().nullish(),
  energy_threshold: z.number().nullish(),
  tarif_above_threshold: z.number().nullish(),
  apply_energy_threshold: z.boolean().nullish(),
  use_index: z.boolean().nullish(),
  use_index_extrapolation: z.boolean().nullish(),
  production_prorata_coef: z.number().nullish(),
});
export type FeedInTarifSolarSemestrialOptFields = z.infer<
  typeof FeedInTarifSolarSemestrialOptFields
>;

export const FeedInTarifHydroOptFields = z.object({
  tarif_components: z.array(TarifComponent).nullish(),
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_hydro_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  quality_majoration: z.number().nullish(),
  quality_coefficient: z.number().nullish(),
  prorata: z.number().nullish(),
  shift_hours: z.number().int().nullish(),
});
export type FeedInTarifHydroOptFields = z.infer<
  typeof FeedInTarifHydroOptFields
>;

export const MarketAccessOptFields = z.object({
  type: z.literal("market_access"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  management_fee_coefficient: z.number().nullish(),
  exclude_neg_price_hours: z.boolean().nullish(),
  neg_prices_source: NegativeHoursSource.nullish(),
  index_price_fallback: IndexPriceFallback.nullish(),
  single_line: z.boolean().nullish(),
});
export type MarketAccessOptFields = z.infer<typeof MarketAccessOptFields>;

export const SpotManagementFeeOptFields = z.object({
  type: z.literal("spot_management_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  formula: z.string().nullish(),
  min_value: z.number().nullish(),
  max_value: z.number().nullish(),
});
export type SpotManagementFeeOptFields = z.infer<
  typeof SpotManagementFeeOptFields
>;

export const MarketAccessImbalanceOptFields = z.object({
  type: z.literal("market_access_imb"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
});
export type MarketAccessImbalanceOptFields = z.infer<
  typeof MarketAccessImbalanceOptFields
>;

export const PpaDaOptFields = z.object({
  type: z.literal("ppa_da"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  management_coef: z.number().nullish(),
});
export type PpaDaOptFields = z.infer<typeof PpaDaOptFields>;

export const PpaDaIndexedFeeOptFields = z.object({
  type: z.literal("ppa_da_indexed_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  management_coef: z.number().nullish(),
  prorata: z.number().nullish(),
});
export type PpaDaIndexedFeeOptFields = z.infer<typeof PpaDaIndexedFeeOptFields>;

export const PPAImbalanceIndexedFeeOptFields = z.object({
  type: z.literal("ppa_imbalance_index_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  positive_imbalance_coef: z.number().nullish(),
  negative_imbalance_coef: z.number().nullish(),
});
export type PPAImbalanceIndexedFeeOptFields = z.infer<
  typeof PPAImbalanceIndexedFeeOptFields
>;

export const PpaFixOptFields = z.object({
  type: z.literal("ppa_fix"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  fix_price: z.number().nullish(),
  management_fee: z.number().nullish(),
  under_positive_spot_prices: z.boolean().nullish(),
});
export type PpaFixOptFields = z.infer<typeof PpaFixOptFields>;

export const PpaManagementFeeOptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ppa_management_fee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  under_positive_spot_prices: z.boolean().nullish(),
});
export type PpaManagementFeeOptFields = z.infer<
  typeof PpaManagementFeeOptFields
>;

export const PpaThresholdOptFields = z.object({
  type: z.literal("ppa_threshold"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif_below_cap: PpaTarifComponent.nullish(),
  tarif_above_cap: PpaTarifComponent.nullish(),
  power_threshold: z.number().nullish(),
  management_fee: z.number().nullish(),
});
export type PpaThresholdOptFields = z.infer<typeof PpaThresholdOptFields>;

export const PpaMultiTarifOptFields = z.object({
  tarif_components: z.array(TarifComponent).nullish(),
  type: z.literal("ppa_tarif_components"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
});
export type PpaMultiTarifOptFields = z.infer<typeof PpaMultiTarifOptFields>;

export const NegativePriceOptFields = z.object({
  type: z.literal("neg_price"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  price_type: PriceType.nullish(),
  use_absolute_value: z.boolean().nullish(),
  management_fee: z.number().nullish(),
  penalty_way_coefficient: z.number().nullish(),
});
export type NegativePriceOptFields = z.infer<typeof NegativePriceOptFields>;

export const CreditNoteCapOptFields = z.object({
  type: z.literal("credit_note_cap"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  with_deferment: z.boolean().nullish(),
  statuses: z.array(InvoiceStatus).nullish(),
  known_deferment: z.array(DefermentRecord).nullish(),
});
export type CreditNoteCapOptFields = z.infer<typeof CreditNoteCapOptFields>;

export const GooOptFields = z.object({
  type: z.literal("goo"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  price: z.number().nullish(),
});
export type GooOptFields = z.infer<typeof GooOptFields>;

export const CfdAutoconsoOptFields = z.object({
  type: z.literal("cfd_autoconso"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number().nullish(),
  premium_autoconso: z.number().nullish(),
  yearly_max_capacity: z.array(MaxCapacityRecord).nullish(),
});
export type CfdAutoconsoOptFields = z.infer<typeof CfdAutoconsoOptFields>;

export const AutoconsoOptFields = z.object({
  type: z.literal("autoconso"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  yearly_tarif: z.number().nullish(),
  yearly_actualisation_rate: z.number().nullish(),
  bonus_malus: z.array(BonusMalusRecord).nullish(),
  payment_delay_ops: z.array(ChainDateOpRecord).nullish(),
  prorata_temporis_mode: z.enum(["timedelta", "ndays"]).nullish(),
});
export type AutoconsoOptFields = z.infer<typeof AutoconsoOptFields>;

export const AutoconsoMasterOptFields = z.object({
  type: z.literal("autoconso_master"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
});
export type AutoconsoMasterOptFields = z.infer<typeof AutoconsoMasterOptFields>;

export const SettlementOptFields = z.object({
  type: z.literal("settlement"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  price: z.number().nullish(),
});
export type SettlementOptFields = z.infer<typeof SettlementOptFields>;

export const UndeliveredEnergyPenaltyOptFields = z.object({
  type: z.literal("undelivered_energy_penalty"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  pae: z.number().nullish(),
  spot_prices_coefficient: z.number().nullish(),
});
export type UndeliveredEnergyPenaltyOptFields = z.infer<
  typeof UndeliveredEnergyPenaltyOptFields
>;

export const FeedInTarifBiogasOptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("ft_biogas_monthly"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  contracted_capacity: z.number().nullish(),
  tarif: z.number().nullish(),
  shift_hours: z.number().int().nullish(),
  use_index: z.boolean().nullish(),
  effluents_rate: z.number().nullish(),
  effluents_rate_threshold: z.number().nullish(),
  effluents_tarif: z.number().nullish(),
});
export type FeedInTarifBiogasOptFields = z.infer<
  typeof FeedInTarifBiogasOptFields
>;

export const PpaThresholdMultiPeriodOptFields = z.object({
  tarif_components: z.array(TarifComponent).nullish(),
  type: z.literal("ppa_threshold_multi_period"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  management_fee: z.number().nullish(),
  epex_fee: z.number().nullish(),
});
export type PpaThresholdMultiPeriodOptFields = z.infer<
  typeof PpaThresholdMultiPeriodOptFields
>;

export const EnergyGuaranteeOptFields = z.object({
  type: z.literal("energy_guarantee"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  fix_price: z.number().nullish(),
  management_fee: z.number().nullish(),
  under_positive_spot_prices: z.boolean().nullish(),
  yearly_cashflow: z.array(z.number()).nullish(),
  monthly_seasonality: z.array(z.number()).nullish(),
});
export type EnergyGuaranteeOptFields = z.infer<typeof EnergyGuaranteeOptFields>;

export const EnergyGuaranteeRiskPremiumOptFields = z.object({
  type: z.literal("energy_guarantee_risk_premium"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  guarantee_percentage: z.number().nullish(),
});
export type EnergyGuaranteeRiskPremiumOptFields = z.infer<
  typeof EnergyGuaranteeRiskPremiumOptFields
>;

export const PpaAsiaOptFields = z.object({
  tarif_components: z.array(TarifComponent).nullish(),
  type: z.literal("ppa_asia"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  ft_cost: z.number().nullish(),
  discount_rate: z.number().nullish(),
});
export type PpaAsiaOptFields = z.infer<typeof PpaAsiaOptFields>;

export const GuaranteeOfCapacityOptFields = z.object({
  type: z.literal("gc"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  records: z.array(GCRecord).nullish(),
  certification_fee: z.number().nullish(),
  management_fee: z.number().nullish(),
});
export type GuaranteeOfCapacityOptFields = z.infer<
  typeof GuaranteeOfCapacityOptFields
>;

export const MaintenanceOptFields = z.object({
  stored_l_index_cfg: LIndexCfg.nullish(),
  indexation: z.array(IndexationParameters).nullish(),
  type: z.literal("maintenance"),
  stored_energy_params: EnergyParametersOptFields.nullish(),
  clause_invoicing_cfg: InvoicingCfg.nullish(),
  tarif: z.number().nullish(),
});
export type MaintenanceOptFields = z.infer<typeof MaintenanceOptFields>;

export const ClauseParametersUpdate = z.discriminatedUnion("type", [
  CfdCr16OptFields,
  CfdCr17OptFields,
  CfdMajorationOptFields,
  FeedInTarifWindMonthlyOptFields,
  FeedInTarifSolarMonthlyOptFields,
  FeedInTarifSolarSemestrialOptFields,
  FeedInTarifHydroOptFields,
  MarketAccessOptFields,
  SpotManagementFeeOptFields,
  MarketAccessImbalanceOptFields,
  PpaDaOptFields,
  PpaDaIndexedFeeOptFields,
  PPAImbalanceIndexedFeeOptFields,
  PpaFixOptFields,
  PpaManagementFeeOptFields,
  PpaThresholdOptFields,
  PpaMultiTarifOptFields,
  NegativePriceOptFields,
  CreditNoteCapOptFields,
  GooOptFields,
  CfdAutoconsoOptFields,
  AutoconsoOptFields,
  AutoconsoMasterOptFields,
  SettlementOptFields,
  UndeliveredEnergyPenaltyOptFields,
  FeedInTarifBiogasOptFields,
  PpaThresholdMultiPeriodOptFields,
  EnergyGuaranteeOptFields,
  EnergyGuaranteeRiskPremiumOptFields,
  PpaAsiaOptFields,
  GuaranteeOfCapacityOptFields,
  MaintenanceOptFields,
]);

export type ClauseParametersUpdate = z.infer<typeof ClauseParametersUpdate>;

export const ClauseUpdateSchema = z.object({
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  alias: z.string().nullish(),
  parameters: ClauseParametersUpdate.nullish(),
});
export type ClauseUpdateSchema = z.infer<typeof ClauseUpdateSchema>;

export const TSMetadata = z.object({
  freq: z.string(),
  unit: z.string(),
  horizon_start: z.coerce.date().nullish(),
  horizon_end: z.coerce.date().nullish(),
});
export type TSMetadata = z.infer<typeof TSMetadata>;

export const version = z.union([z.string(), z.coerce.date()]).nullish();
export type version = z.infer<typeof version>;

export const VersionStatistics = z.object({
  min_index: z.coerce.date(),
  max_index: z.coerce.date(),
  completeness: z.number(),
  min_value: z.number(),
  max_value: z.number(),
  avg_value: z.number(),
  count_value: z.number().int(),
  version: z.coerce.date(),
});
export type VersionStatistics = z.infer<typeof VersionStatistics>;

export const Statistics = z.object({
  min_index: z.coerce.date(),
  max_index: z.coerce.date(),
  completeness: z.number(),
  min_value: z.number(),
  max_value: z.number(),
  avg_value: z.number(),
  count_value: z.number().int(),
  tz: z.string(),
  freq: z.string(),
  versions: z.array(VersionStatistics).nullish().default([]),
  version_freq: z.string().nullish(),
  version_delta_horizon: z.string().nullish(),
});
export type Statistics = z.infer<typeof Statistics>;

export const Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post =
  z.object({ tags: z.array(z.union([z.string(), z.array(z.string())])) });
export type Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post =
  z.infer<
    typeof Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post
  >;

export const Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post =
  z.object({ tags: z.array(z.union([z.string(), z.array(z.string())])) });
export type Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post =
  z.infer<
    typeof Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post
  >;

export const PartnerSummarySchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  image_link: z.string().nullish(),
  url: z.string().nullish(),
  kind: z.string().nullish(),
  capacity: z.number().nullish(),
  contracts_number: z.number().int().nullish(),
  contacts: z.array(ContactInfoSchema),
});
export type PartnerSummarySchema = z.infer<typeof PartnerSummarySchema>;

export const FilesQueryModelName = z.enum([
  "assetmanager",
  "assetowner",
  "holding",
  "site",
  "contract",
  "deliverypoint",
]);
export type FilesQueryModelName = z.infer<typeof FilesQueryModelName>;

export const FileInfoSchema = z.object({
  filename: z.string(),
  size: z.number().int(),
  modified: z.coerce.date(),
  model: FilesQueryModelName,
  uuid: z.string(),
});
export type FileInfoSchema = z.infer<typeof FileInfoSchema>;

export const Body_post_files_filtered_by_model_api__model___uuid__files_post =
  z.object({ files: z.array(z.instanceof(File)) });
export type Body_post_files_filtered_by_model_api__model___uuid__files_post =
  z.infer<
    typeof Body_post_files_filtered_by_model_api__model___uuid__files_post
  >;

export const EventType = z.enum(["stop", "downgraded", "informative"]);
export type EventType = z.infer<typeof EventType>;

export const EventCause = z.enum([
  "preventive_maintenance",
  "corrective_maintenance",
  "negative_price",
  "network",
  "breakdown",
]);
export type EventCause = z.infer<typeof EventCause>;

export const EventLosses = z.object({
  energy_loss: z.number().nullish(),
  revenue_loss: z.number().nullish(),
});
export type EventLosses = z.infer<typeof EventLosses>;

export const EventSource = z.enum(["ai", "power_production", "manual"]);
export type EventSource = z.infer<typeof EventSource>;

export const EventSchema = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  description: z.string().nullish(),
  is_external: z.boolean(),
  is_validated: z.boolean(),
  uuid: z.string().uuid(),
  site: SiteSchemaShort,
  available_capacity: z.number(),
  creation_date: z.coerce.date(),
  last_update: z.coerce.date(),
  event_type: EventType,
  cause: EventCause.nullish(),
  losses: EventLosses.nullish(),
  source: EventSource,
});
export type EventSchema = z.infer<typeof EventSchema>;

export const EventSchemaUpdate = z.object({
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  event_type: EventType.nullish(),
  cause: EventCause.nullish(),
  description: z.string().nullish(),
  available_capacity: z.number().nullish(),
  unavailable_capacity: z.number().nullish(),
  available_percentage: z.number().gte(0).lte(1).nullish(),
  unavailable_percentage: z.number().gte(0).lte(1).nullish(),
  site_uuid: z.string().uuid().nullish(),
  is_external: z.boolean().nullish(),
  is_validated: z.boolean().nullish(),
  source: EventSource.nullish(),
});
export type EventSchemaUpdate = z.infer<typeof EventSchemaUpdate>;

export const EventSchemaCreate = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  description: z.string().nullish(),
  is_external: z.boolean(),
  is_validated: z.boolean(),
  event_type: EventType.nullish(),
  cause: EventCause.nullish(),
  available_capacity: z.number().nullish(),
  unavailable_capacity: z.number().nullish(),
  available_percentage: z.number().nullish(),
  unavailable_percentage: z.number().nullish(),
  site_uuid: z.string().uuid(),
});
export type EventSchemaCreate = z.infer<typeof EventSchemaCreate>;

export const DocKind = z.enum([
  "invoice",
  "contract",
  "productivity_study",
  "production_report",
  "finance_report",
  "operation_report",
  "commissioning_report",
  "performance_test_report",
  "pr_report",
  "cumulative_index_report",
  "data_extract",
  "contract_data_extract",
  "load_profile_extract",
  "partner_extract",
  "invoice_lines_report",
  "invoices_accounting_report",
  "sage_accounting_report",
  "edf_oa_account_details",
]);
export type DocKind = z.infer<typeof DocKind>;

export const DocumentQueryModelName = z.enum([
  "assetmanager",
  "assetowner",
  "holding",
  "site",
  "portfolio",
  "contract",
  "invoice",
]);
export type DocumentQueryModelName = z.infer<typeof DocumentQueryModelName>;

export const DocumentSchemaShort = z.object({
  uuid: z.string().uuid(),
  kind: DocKind.nullish(),
  filename: z.string(),
  creation_date: z.coerce.date(),
  last_update: z.coerce.date(),
  target_uuid: z.string().uuid(),
  target_model: DocumentQueryModelName,
  file_exists: z.boolean(),
});
export type DocumentSchemaShort = z.infer<typeof DocumentSchemaShort>;

export const Body_create_document_api__model___uuid__document_post = z.object({
  files: z.array(z.instanceof(File)),
  kind: DocKind,
});
export type Body_create_document_api__model___uuid__document_post = z.infer<
  typeof Body_create_document_api__model___uuid__document_post
>;

export const DocGenConfigSchema = z.object({
  kind: DocKind,
  skeleton_article_name: z.string().nullish(),
  article_names: z.union([z.array(z.string()), z.record(z.array(z.string()))]),
  static_input: z.record(z.any()),
  description: z.string().nullish(),
});
export type DocGenConfigSchema = z.infer<typeof DocGenConfigSchema>;

export const DocumentSchema = z.object({
  uuid: z.string().uuid(),
  kind: DocKind.nullish(),
  filename: z.string(),
  creation_date: z.coerce.date(),
  last_update: z.coerce.date(),
  target_uuid: z.string().uuid(),
  target_model: DocumentQueryModelName,
  file_exists: z.boolean(),
  docgen_config: DocGenConfigSchema.nullish(),
  gen_input: z.record(z.any()),
});
export type DocumentSchema = z.infer<typeof DocumentSchema>;

export const Body_refresh_documents_api_document_post = z.object({
  uuids: z.array(z.string()),
});
export type Body_refresh_documents_api_document_post = z.infer<
  typeof Body_refresh_documents_api_document_post
>;

export const NoKwargsReport = z.object({
  uuids: z.array(z.string()),
  kind: z.enum([
    "performance_test_report",
    "commissioning_report",
    "invoice_lines_report",
    "partner_extract",
  ]),
  filename: z.string().nullish(),
});
export type NoKwargsReport = z.infer<typeof NoKwargsReport>;

export const DateRangeReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.enum([
    "pr_report",
    "invoices_accounting_report",
    "sage_accounting_report",
    "contract_data_extract",
  ]),
  filename: z.string().nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
});
export type DateRangeReportKwargs = z.infer<typeof DateRangeReportKwargs>;

export const GSDataCategory = z.enum([
  "realtime",
  "metering",
  "producible",
  "operation",
  "revenue",
  "budget",
  "self_consumption",
]);
export type GSDataCategory = z.infer<typeof GSDataCategory>;

export const DataExtractReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("data_extract"),
  filename: z.string().nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  categories: z.array(GSDataCategory).nullish().default(["metering"]),
  aggregate: z.boolean().nullish(),
  freq: z
    .enum(["1D", "1MS", "1YS", "1W-MON", "1AS-JAN"])
    .nullish()
    .default("1MS"),
});
export type DataExtractReportKwargs = z.infer<typeof DataExtractReportKwargs>;

export const CumIndexReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("cumulative_index_report"),
  filename: z.string().nullish(),
  date: z.coerce.date().nullish(),
});
export type CumIndexReportKwargs = z.infer<typeof CumIndexReportKwargs>;

export const GSMetricName = z.unknown();
export type GSMetricName = z.infer<typeof GSMetricName>;

export const BudgetScope = z.enum(["budget", "business_plan", "bank"]);
export type BudgetScope = z.infer<typeof BudgetScope>;

export const OperationReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("operation_report"),
  filename: z.string().nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  title: z.string().nullish().default("Rapport d'Exploitation"),
  metric_names: z
    .array(GSMetricName)
    .nullish()
    .default([
      "production",
      "availability_injection",
      "pr",
      "availability_technical",
    ]),
  budget_scope: BudgetScope.nullish(),
});
export type OperationReportKwargs = z.infer<typeof OperationReportKwargs>;

export const FinanceReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("finance_report"),
  filename: z.string().nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  title: z.string().nullish().default("Rapport Financier"),
  metric_names: z
    .array(GSMetricName)
    .nullish()
    .default(["production", "revenue"]),
  budget_scope: BudgetScope.nullish(),
});
export type FinanceReportKwargs = z.infer<typeof FinanceReportKwargs>;

export const LoadProfileExtractReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("load_profile_extract"),
  filename: z.string().nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  reference: z.string().nullish(),
  ways: z
    .array(z.enum(["PROD", "CONS"]))
    .nullish()
    .default(["PROD"]),
});
export type LoadProfileExtractReportKwargs = z.infer<
  typeof LoadProfileExtractReportKwargs
>;

export const generate_documents_Body = z.discriminatedUnion("kind", [
  NoKwargsReport,
  DateRangeReportKwargs,
  DataExtractReportKwargs,
  CumIndexReportKwargs,
  OperationReportKwargs,
  FinanceReportKwargs,
  LoadProfileExtractReportKwargs,
]);

export type generate_documents_Body = z.infer<typeof generate_documents_Body>;

export const HorizonLabel = z.enum([
  "ytd",
  "yt_last_month",
  "yt_quarter",
  "last_year",
  "rolling_year",
  "current_month",
  "last_month",
  "rolling_month",
  "current_week",
  "last_week",
  "rolling_week",
  "today",
  "yesterday",
  "custom",
  "rolling_0_30",
  "rolling_0_90",
  "rolling_30_60",
  "rolling_30_120",
]);
export type HorizonLabel = z.infer<typeof HorizonLabel>;

export const ComputedHorizon = z.object({
  label: HorizonLabel,
  base: z.array(z.coerce.date()).nullish(),
  versus: z.array(z.coerce.date()).nullish(),
  base_str: z.string().nullish(),
  versus_str: z.string().nullish(),
});
export type ComputedHorizon = z.infer<typeof ComputedHorizon>;

export const MetricStatus = z.enum([
  "missing",
  "missing_configuration",
  "good",
  "warning",
  "error",
  "compute_error",
  "skipped",
  "na",
]);
export type MetricStatus = z.infer<typeof MetricStatus>;

export const MetricSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  horizon: ComputedHorizon.nullish(),
  value: z.unknown().nullish(),
  last_refresh: z.coerce.date().nullish(),
  status: MetricStatus,
  err_details: z.unknown().nullish(),
});
export type MetricSchema = z.infer<typeof MetricSchema>;

export const DeliveryPointSchemaUpdate = z.object({
  code: z.string().nullish(),
  reference: z.string().nullish(),
  network_voltage: NetworkVoltageSchema.nullish(),
  code_kind: DeliveryPointCodeKind.nullish(),
  type: DeliveryPointType.nullish(),
  extra: NetworkCfg.nullish(),
});
export type DeliveryPointSchemaUpdate = z.infer<
  typeof DeliveryPointSchemaUpdate
>;

export const Way = z.enum(["consumption", "production"]);
export type Way = z.infer<typeof Way>;

export const MeasureType = z.enum(["load_profile", "index"]);
export type MeasureType = z.infer<typeof MeasureType>;

export const Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post =
  z.object({
    ways: z.array(Way).nullish().default(["consumption", "production"]),
    measure_types: z
      .array(MeasureType)
      .nullish()
      .default(["load_profile", "index"]),
    start: z.coerce.date(),
    end: z.coerce.date(),
  });
export type Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post =
  z.infer<
    typeof Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post
  >;

export const Body_trigger_soda_backfill_api_etl_soda__uuid__backfill_post =
  z.object({ start: z.coerce.date(), end: z.coerce.date() });
export type Body_trigger_soda_backfill_api_etl_soda__uuid__backfill_post =
  z.infer<typeof Body_trigger_soda_backfill_api_etl_soda__uuid__backfill_post>;

export const ComparisonHorizonType = z.enum(["last_year", "preceding", "none"]);
export type ComparisonHorizonType = z.infer<typeof ComparisonHorizonType>;

export const _BaseGaugeModel = z.object({
  value: z.number().nullish(),
  versus: z.number().nullish(),
  weight: z.number().nullish().default(1),
  rate: z.number().nullish(),
  ratio: z.number().nullish(),
  diff: z.number().nullish(),
});
export type _BaseGaugeModel = z.infer<typeof _BaseGaugeModel>;

export const GaugeModel = z.object({
  value: z.number().nullish(),
  versus: z.number().nullish(),
  weight: z.number().nullish().default(1),
  rate: z.number().nullish(),
  ratio: z.number().nullish(),
  diff: z.number().nullish(),
  gauge_budget: _BaseGaugeModel.nullish(),
});
export type GaugeModel = z.infer<typeof GaugeModel>;

export const GSGaugeMetric = z.object({
  name: z.string(),
  gauge: GaugeModel,
  horizon: ComputedHorizon,
});
export type GSGaugeMetric = z.infer<typeof GSGaugeMetric>;

export const GSChartType = z.enum([
  "production",
  "finance",
  "operation",
  "self_consumption",
]);
export type GSChartType = z.infer<typeof GSChartType>;

export const MachineType = z.enum([
  "wind_turbine",
  "solar_field",
  "cogeneration",
  "hydro_turbine",
]);
export type MachineType = z.infer<typeof MachineType>;

export const MachineSubType = z.enum([
  "wind_turbine_onshore",
  "wind_turbine_offshore",
  "solar_field_ground_mounted",
  "solar_field_rooftop",
  "solar_field_canopy",
  "hydro_turbine_run_of_river",
  "hydro_turbine_pumped_storage",
  "hydro_turbine_reservoir",
  "cogeneration_biomass",
  "cogeneration_waste",
  "cogeneration_other",
]);
export type MachineSubType = z.infer<typeof MachineSubType>;

export const technos = z
  .array(z.union([MachineType, MachineSubType]))
  .nullish();
export type technos = z.infer<typeof technos>;

export const GSSchema = z.object({
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  freq: z.enum(["1MS", "1D"]),
  site: InvoiceSiteSchema,
  last_update: z.coerce.date(),
});
export type GSSchema = z.infer<typeof GSSchema>;

export const GSRefreshModel = z.enum([
  "site",
  "assetowner",
  "assetmanager",
  "holding",
]);
export type GSRefreshModel = z.infer<typeof GSRefreshModel>;

export const GSRefreshParams = z.object({
  model: GSRefreshModel,
  uuids: z.array(z.string()),
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  refresh_freqs: z
    .array(z.enum(["1D", "1MS"]))
    .nullish()
    .default(["1D", "1MS"]),
  to_refresh: z
    .array(GSDataCategory)
    .nullish()
    .default([
      "realtime",
      "metering",
      "producible",
      "operation",
      "revenue",
      "budget",
      "self_consumption",
    ]),
});
export type GSRefreshParams = z.infer<typeof GSRefreshParams>;

export const GSKpiLevel = z.enum([
  "site",
  "assetowner",
  "holding",
  "regional",
  "portfolio",
  "region",
  "machine_type",
  "machine_subtype",
]);
export type GSKpiLevel = z.infer<typeof GSKpiLevel>;

export const SiteGSKpi = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: GaugeModel.nullish(),
  revenue: GaugeModel.nullish(),
  pr: GaugeModel.nullish(),
  availability_injection: GaugeModel.nullish(),
  target_model: z.literal("site"),
  target_uuid: z.string().uuid(),
  portfolio: z.string().nullish(),
  region: z.string().nullish(),
});
export type SiteGSKpi = z.infer<typeof SiteGSKpi>;

export const SubGroupGSKpiUuid = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: GaugeModel.nullish(),
  revenue: GaugeModel.nullish(),
  pr: GaugeModel.nullish(),
  availability_injection: GaugeModel.nullish(),
  target_model: GSKpiLevel,
  target_uuid: z.string().uuid(),
  children: z.array(SiteGSKpi),
});
export type SubGroupGSKpiUuid = z.infer<typeof SubGroupGSKpiUuid>;

export const SubGroupGSKpi = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: GaugeModel.nullish(),
  revenue: GaugeModel.nullish(),
  pr: GaugeModel.nullish(),
  availability_injection: GaugeModel.nullish(),
  target_model: GSKpiLevel,
  children: z.array(SiteGSKpi),
});
export type SubGroupGSKpi = z.infer<typeof SubGroupGSKpi>;

export const GroupGSKpi = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: GaugeModel.nullish(),
  revenue: GaugeModel.nullish(),
  pr: GaugeModel.nullish(),
  availability_injection: GaugeModel.nullish(),
  target_model: z.enum(["region", "machine_subtype", "machine_type"]),
  children: z.array(z.union([SiteGSKpi, SubGroupGSKpiUuid, SubGroupGSKpi])),
});
export type GroupGSKpi = z.infer<typeof GroupGSKpi>;

export const GroupGSKpiUuid = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: GaugeModel.nullish(),
  revenue: GaugeModel.nullish(),
  pr: GaugeModel.nullish(),
  availability_injection: GaugeModel.nullish(),
  target_model: z.enum(["portfolio", "holding", "assetowner"]),
  target_uuid: z.string().uuid(),
  children: z.array(z.union([SiteGSKpi, SubGroupGSKpiUuid, SubGroupGSKpi])),
});
export type GroupGSKpiUuid = z.infer<typeof GroupGSKpiUuid>;

export const GSKpiMachineSubType = z.object({
  n_sites: z.number().int(),
  n_spvs: z.number().int(),
  capacity: z.number(),
  production: GaugeModel.nullish(),
  production_budget: z.number().nullish(),
  revenue: GaugeModel.nullish(),
  revenue_budget: z.number().nullish(),
  techno: MachineSubType,
});
export type GSKpiMachineSubType = z.infer<typeof GSKpiMachineSubType>;

export const GSKpiTechno = z.object({
  n_sites: z.number().int(),
  n_spvs: z.number().int(),
  capacity: z.number(),
  production: GaugeModel.nullish(),
  production_budget: z.number().nullish(),
  revenue: GaugeModel.nullish(),
  revenue_budget: z.number().nullish(),
  techno: MachineType,
  children: z.array(GSKpiMachineSubType).nullish(),
});
export type GSKpiTechno = z.infer<typeof GSKpiTechno>;

export const DHCheckKind = z.enum([
  "config",
  "completeness",
  "freshness",
  "consistency",
]);
export type DHCheckKind = z.infer<typeof DHCheckKind>;

export const DHCategory = z.enum(["metering", "finance", "operation"]);
export type DHCategory = z.infer<typeof DHCategory>;

export const DHFlagCode = z.enum([
  "BCKM023",
  "CNC",
  "CNS",
  "ELD_NOT_CONFIGURED",
  "EXPMO",
  "ISPC",
  "MDE",
  "MDPC",
  "MF",
  "MI",
  "MPO",
  "MPRM",
  "NC",
  "NCCI",
  "NMDAT",
  "PDFA",
  "R172_LATE",
  "R172_WRONG",
  "WCS",
]);
export type DHFlagCode = z.infer<typeof DHFlagCode>;

export const DHComments = z.object({
  error: z.array(z.string()),
  info: z.array(z.string()),
  aggregated: z.array(z.string()),
});
export type DHComments = z.infer<typeof DHComments>;

export const BaseDHSiteSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  external_id: z.string().nullish(),
  dp_codes: z.array(z.string()),
  is_external: z.boolean(),
  assetowner: CompanySchemaShort,
  capacity: z.number(),
  portfolio: Portfolio.nullish(),
  commissioning: z.coerce.date().nullish(),
});
export type BaseDHSiteSchema = z.infer<typeof BaseDHSiteSchema>;

export const DHAssignee = z.enum(["client", "renewex"]);
export type DHAssignee = z.infer<typeof DHAssignee>;

export const DHActionLabel = z.enum([
  "inspect_config",
  "manual_backfill",
  "ask_for_mandate",
  "configure_this_case",
  "configure_timeseries",
  "inspect_goldensource",
  "send_contract",
  "fix_contract_synchronization",
]);
export type DHActionLabel = z.infer<typeof DHActionLabel>;

export const DHJob = z.enum([
  "backfill",
  "enedis_inspect_orders_job",
  "enedis_order_measurements_job",
  "enedis_order_m023_backfill_files_job",
  "enedis_files_job",
  "edfoa_synchronize_contracts_job",
]);
export type DHJob = z.infer<typeof DHJob>;

export const BaseDHFlagSchema = z.object({
  code: DHFlagCode,
  name: z.string(),
  assignee: DHAssignee.nullish(),
  action_label: DHActionLabel.nullish(),
  job: DHJob.nullish(),
});
export type BaseDHFlagSchema = z.infer<typeof BaseDHFlagSchema>;

export const DHSchema = z.object({
  uuid: z.string().uuid(),
  freq: z.enum(["1MS", "1D"]).nullish(),
  score: z.number(),
  check: DHCheckKind,
  category: DHCategory,
  comments: DHComments,
  last_update: z.coerce.date(),
  site: BaseDHSiteSchema,
  flags: z.array(BaseDHFlagSchema),
});
export type DHSchema = z.infer<typeof DHSchema>;

export const BaseDHSchema = z.object({
  uuid: z.string().uuid(),
  freq: z.enum(["1MS", "1D"]).nullish(),
  score: z.number(),
  check: DHCheckKind,
  category: DHCategory,
  comments: DHComments,
  last_update: z.coerce.date(),
});
export type BaseDHSchema = z.infer<typeof BaseDHSchema>;

export const SiteDHSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  capacity: z.number(),
  dp_codes: z.array(z.string()),
  commissioning: z.coerce.date().nullish(),
  external_id: z.string().nullish(),
  datahealths: z.array(BaseDHSchema),
  is_external: z.boolean(),
});
export type SiteDHSchema = z.infer<typeof SiteDHSchema>;

export const DHScoreSchema = z.object({
  score: z.number(),
  name: z.string().nullish(),
  subscores: z.array(z.record(z.any())),
});
export type DHScoreSchema = z.infer<typeof DHScoreSchema>;

export const Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post =
  z.object({
    checks: z.array(DHCheckKind).nullish(),
    categories: z.array(DHCategory).nullish(),
  });
export type Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post =
  z.infer<
    typeof Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post
  >;

export const PastillaSchema = z.object({
  name: PastillaName,
  status: PastillaStatus,
  site: InvoiceSiteSchema,
  horizon_label: HorizonLabel,
  sources: z.array(z.string()),
  gauge_value: z.number().nullish(),
  budget_value: z.number().nullish(),
  last_update: z.coerce.date(),
  last_status_change: z.coerce.date(),
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  comment: z.string().nullish(),
});
export type PastillaSchema = z.infer<typeof PastillaSchema>;

export const FilterKind = z.enum(["regions", "assetowners", "portfolios"]);
export type FilterKind = z.infer<typeof FilterKind>;

export const NoteTagSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  code: z.string(),
  category: z.string().nullish(),
  color: z.string().nullish(),
  description: z.string().nullish(),
});
export type NoteTagSchema = z.infer<typeof NoteTagSchema>;

export const NoteQueryModelName = z.enum([
  "assetmanager",
  "assetowner",
  "holding",
  "site",
  "portfolio",
  "contract",
]);
export type NoteQueryModelName = z.infer<typeof NoteQueryModelName>;

export const NoteSchema = z.object({
  uuid: z.string().uuid(),
  body: z.string().nullish(),
  tags: z.array(NoteTagSchema),
  target_uuid: z.string().uuid(),
  target_model: NoteQueryModelName,
  target_name: z.string(),
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  creation_date: z.coerce.date(),
  update_date: z.coerce.date(),
  created_by: z.string().nullish(),
  updated_by: z.string().nullish(),
  pinned: z.boolean(),
  level: z.string().nullish(),
  archived: z.boolean(),
});
export type NoteSchema = z.infer<typeof NoteSchema>;

export const NoteSchemaCreate = z.object({
  body: z.string(),
  tags: z.array(z.string()).nullish(),
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  pinned: z.boolean().nullish(),
  level: z.string().nullish(),
});
export type NoteSchemaCreate = z.infer<typeof NoteSchemaCreate>;

export const NoteSchemaUpdate = z.object({
  body: z.string().nullish(),
  tags: z.array(z.string()).nullish(),
  start: z.coerce.date().nullish(),
  end: z.coerce.date().nullish(),
  pinned: z.boolean().nullish(),
  level: z.string().nullish(),
});
export type NoteSchemaUpdate = z.infer<typeof NoteSchemaUpdate>;

export const NoteTagSchemaCreate = z.object({
  name: z.string(),
  code: z.string(),
  category: z.string().nullish(),
  color: z.string().nullish(),
  description: z.string().nullish(),
});
export type NoteTagSchemaCreate = z.infer<typeof NoteTagSchemaCreate>;

export const NoteTagSchemaUpdate = z.object({
  name: z.string().nullish(),
  code: z.string().nullish(),
  category: z.string().nullish(),
  color: z.string().nullish(),
  description: z.string().nullish(),
});
export type NoteTagSchemaUpdate = z.infer<typeof NoteTagSchemaUpdate>;

export const endpoints = makeApi([
  {
    method: "get",
    path: "/api/:model/:uuid/contracts",
    alias: "get_contracts_filtered_by_model",
    description: `List of contracts`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetmanager", "assetowner", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(BaseContractSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/datahealth",
    alias: "get_datahealth",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "assetmanager",
          "holding",
          "portfolio",
          "region",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.enum(["1MS", "1D"]).nullish(),
      },
      {
        name: "checks",
        type: "Query",
        schema: z.array(DHCheckKind).nullish(),
      },
      {
        name: "categories",
        type: "Query",
        schema: z.array(DHCategory).nullish(),
      },
      {
        name: "is_external",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "technos",
        type: "Query",
        schema: technos,
      },
      {
        name: "flag_codes",
        type: "Query",
        schema: z.array(DHFlagCode).nullish(),
      },
    ],
    response: z.array(DHSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/datahealth/refresh",
    alias: "datahealth_model_level_refresh",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "assetmanager",
          "holding",
          "portfolio",
          "region",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: SiteDHSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/document",
    alias: "get_documents_filtered_by_model",
    description: `Returns all documents of the given model
We force the children model according to the following rules:
- AssetManager : AM + Holding files
- Holding : Holding + AO files
- AssetOwner : AO + Site files
- Site : Site + Contract files
- Contract : Contract + Invoices files`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
          "invoice",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("1900-01-01T00:00:00+00:00")),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("2200-01-01T00:00:00+00:00")),
      },
      {
        name: "docgen_kind",
        type: "Query",
        schema: DocKind.nullish(),
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/document",
    alias: "create_document",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Body_create_document_api__model___uuid__document_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
          "invoice",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/document/detailed",
    alias: "get_detailed_document_by_model",
    description: `Returns all detailed filtered documents of the given model`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
          "invoice",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("1900-01-01T00:00:00+00:00")),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("2200-01-01T00:00:00+00:00")),
      },
      {
        name: "docgen_kind",
        type: "Query",
        schema: DocKind.nullish(),
      },
    ],
    response: z.array(DocumentSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/events",
    alias: "get_events_filtered_by_model",
    description: `Retrieve list of events from a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("1900-01-01T00:00:00+00:00")),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("2200-01-01T00:00:00+00:00")),
      },
    ],
    response: z.array(EventSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/files",
    alias: "post_files_filtered_by_model",
    description: `Upload file(s) related to a specific model instance.`,
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ files: z.array(z.instanceof(File)) }),
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/files/content",
    alias: "download_file_by_model",
    description: `Get the content of a file associated to a specific model instance.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "filename",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/:model/:uuid/files/content",
    alias: "delete_file_by_model",
    description: `Delete a file using the model, uuid and the filename of the target.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "filename",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/files/infos",
    alias: "get_fileinfos_filtered_by_instance",
    description: `List of files informations related to a specific model instance.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(FileInfoSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource",
    alias: "get_goldensource_filtered_by_model",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.enum(["1D", "1MS"]),
      },
    ],
    response: z.array(GSSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/gauge",
    alias: "get_goldensource_gauge",
    description: `Get metrics associated to a model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "name",
        type: "Query",
        schema: GSMetricName,
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "comparison_horizon_type",
        type: "Query",
        schema: ComparisonHorizonType,
      },
      {
        name: "is_external",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: GSGaugeMetric,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/kpis",
    alias: "get_goldensource_kpis",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "aggregation_levels",
        type: "Query",
        schema: z.array(GSKpiLevel).nullish(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "is_external",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "technos",
        type: "Query",
        schema: technos,
      },
      {
        name: "portfolios",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "regions",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z.array(
      z.union([
        SiteGSKpi,
        GroupGSKpi,
        GroupGSKpiUuid,
        SubGroupGSKpi,
        SubGroupGSKpiUuid,
      ])
    ),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/kpis/techno",
    alias: "get_goldensource_kpis_per_techno",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "is_external",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z.array(GSKpiTechno),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/timeseries",
    alias: "get_goldensource_timeseries",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "chart_type",
        type: "Query",
        schema: GSChartType.nullish(),
      },
      {
        name: "columns",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.enum(["1D", "1MS", "1YS", "1W-MON", "1AS-JAN"]),
      },
      {
        name: "is_external",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "technos",
        type: "Query",
        schema: technos,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/invoices",
    alias: "get_invoices_filtered_by_model",
    description: `Retrieve list of invoices from a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "holding",
          "assetmanager",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
    ],
    response: z.array(InvoiceSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/invoices/compute",
    alias: "compute_invoice_from_model",
    description: `Triggers the computation of Invoices related to
the selected model instance for the selected horizon.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceComputationSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/invoices/publish",
    alias: "publish_invoice_from_model",
    description: `Publish invoices invoices related to the selected model instance
for the selected horizon.

When the model is a specific invoice, horizon given is discarded.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(InvoiceSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/invoices/submit",
    alias: "submit_invoice_from_model",
    description: `Submit invoices related to the selected model instance
for the selected horizon.

When the model is a specific invoice, horizon given is discarded.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          start: z.coerce.date().nullish(),
          end: z.coerce.date().nullish(),
        }),
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(InvoiceSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/invoices/sync",
    alias: "get_invoices_filtered_by_last_meaningful_update",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "holding",
          "assetmanager",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
    ],
    response: z.array(InvoiceSchemaSync),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/metric",
    alias: "get_metrics",
    description: `Get metrics associated to a model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "assetowner", "holding", "site"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "horizon",
        type: "Query",
        schema: HorizonLabel.nullish(),
      },
    ],
    response: z.array(MetricSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/note",
    alias: "get_notes_filtered_by_model",
    description: `Retrieve notes from a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "tags",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "include_archived",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "pinned",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "level",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "get_all_children",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z.array(NoteSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/note",
    alias: "create_note",
    description: `Create a single note tag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteSchemaCreate,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/pastillas",
    alias: "get_pastillas_filtered_by_model",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "site", "assetowner", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "name",
        type: "Query",
        schema: PastillaName.nullish(),
      },
      {
        name: "horizon_label",
        type: "Query",
        schema: HorizonLabel.nullish(),
      },
      {
        name: "status",
        type: "Query",
        schema: PastillaStatus.nullish(),
      },
    ],
    response: z.array(PastillaSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/pastillas/underperforming",
    alias: "get_underperforming_pastillas",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["holding", "assetowner", "assetmanager"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(PastillaSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/sites",
    alias: "get_sites_filtered_by_model",
    description: `list of sites`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "portfolio",
          "region",
          "site",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "is_external",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "statuses",
        type: "Query",
        schema: z.array(SiteStatus).nullish(),
      },
    ],
    response: z.array(SiteSchemaOverview),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/sites/datahealth",
    alias: "get_sites_with_datahealth_filtered_by_model",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "portfolio",
          "region",
          "site",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(SiteDHSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/timeseries/data",
    alias: "get_timeseries_data_from_model",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "tenderrequest", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "version",
        type: "Query",
        schema: version,
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "unit",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "missing_freq_tol",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "io_format",
        type: "Query",
        schema: IOFormatDF.nullish().default("feather"),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/timeseries/tsids",
    alias: "get_timeseries_ids_from_model",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "tenderrequest", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(z.string()),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/document",
    alias: "generate_documents",
    description: `Generate document report`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: generate_documents_Body,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "assetowner", "holding", "site"]),
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/files/infos",
    alias: "get_fileinfos_filtered_by_model",
    description: `List of files informations related to a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuids",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z.array(FileInfoSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/auth/me",
    alias: "get_me",
    requestFormat: "json",
    response: User,
  },
  {
    method: "get",
    path: "/api/companies/:uuid",
    alias: "get_company_details",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: BaseCompanySchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/contracts/:uuid",
    alias: "get_contract",
    description: `Contract Details`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ContractSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/contracts/:uuid",
    alias: "update_contract",
    description: `Update a single contract`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ContractSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ContractSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/contracts/clause/:uuid",
    alias: "update_clause",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ClauseUpdateSchema,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ClauseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/datahealth/:uuid/refresh",
    alias: "datahealth_refresh",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: DHScoreSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/deliverypoints/:uuid",
    alias: "update_deliverypoint",
    description: `Update a single deliverypoint`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: DeliveryPointSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: DeliveryPointSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/document",
    alias: "refresh_documents",
    description: `Refresh document`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ uuids: z.array(z.string()) }),
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/document/:uuid",
    alias: "get_document",
    description: `Retrieve a single document from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: DocumentSchemaShort,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/document/:uuid",
    alias: "delete_document",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/document/:uuid/content",
    alias: "download_document",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/etl/enedis/:uuid/backfill",
    alias: "trigger_enedis_backfill",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.boolean(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/etl/soda/:uuid/backfill",
    alias: "trigger_soda_backfill",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ start: z.coerce.date(), end: z.coerce.date() }),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.boolean(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/events",
    alias: "create_event",
    description: `Create a single event`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: EventSchemaCreate,
      },
    ],
    response: EventSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/events/:uuid",
    alias: "get_event",
    description: `Retrieve a single invoice from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: EventSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/events/:uuid",
    alias: "delete_event",
    description: `Delete a single event`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/events/:uuid",
    alias: "update_event",
    description: `Update a single event`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: EventSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: EventSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/filters",
    alias: "get_filters_values",
    requestFormat: "json",
    parameters: [
      {
        name: "kinds",
        type: "Query",
        schema: z.array(FilterKind),
      },
    ],
    response: z.record(z.array(z.string())),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/filters/:model/:uuid/docgen_configs",
    alias: "get_defined_docgen_configs",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "assetowner", "holding", "site"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(DocGenConfigSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/goldensource/refresh",
    alias: "refresh_goldensources",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: GSRefreshParams,
      },
    ],
    response: z.boolean(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/invoices/:uuid",
    alias: "get_invoice",
    description: `Retrieve a single invoice from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/invoices/:uuid",
    alias: "patch_invoice",
    description: `Update a single invoice`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: InvoiceSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/:status",
    alias: "set_invoice_definitive_status",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "status",
        type: "Path",
        schema: z.enum(["submitted", "paid"]),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/accounted",
    alias: "set_invoice_accounted",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ is_accounted: z.boolean().nullish().default(true) }),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/cancel",
    alias: "cancel_invoice",
    description: `Apply cancel action for specific invoice`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "cancel_action",
        type: "Query",
        schema: InvoiceCancelActions.nullish().default("reject"),
      },
    ],
    response: z.array(InvoiceSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/invoices/:uuid/document",
    alias: "get_invoice_pdf",
    description: `Retrieve a single invoice document from its invoice uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/invoices/:uuid/excel",
    alias: "get_invoice_data",
    description: `Retrieve a single invoice data from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/submit_to_zero",
    alias: "submit_invoice_to_zero",
    description: `Apply submit_to_zero action for specific invoice`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/metric/:uuid",
    alias: "compute_metric",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: MetricSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/note/:uuid",
    alias: "get_note",
    description: `Retrieve a single note from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/note/:uuid",
    alias: "delete_note",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/note/:uuid",
    alias: "patch_note",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/note/:uuid/archive",
    alias: "archive_note",
    description: `Archive a note`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/notetag",
    alias: "get_note_tag",
    description: `get all notetags and can include or note common notetags`,
    requestFormat: "json",
    parameters: [
      {
        name: "include_commons",
        type: "Query",
        schema: z.boolean().nullish().default(true),
      },
    ],
    response: z.array(NoteTagSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/notetag",
    alias: "create_notetag",
    description: `Create a single notetag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteTagSchemaCreate,
      },
    ],
    response: NoteTagSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/notetag/:uuid",
    alias: "delete_notetag",
    description: `Delete a single notetag`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/notetag/:uuid",
    alias: "patch_notetag",
    description: `Update a single notetag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteTagSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteTagSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/partners",
    alias: "get_partners",
    description: `Partners Summary`,
    requestFormat: "json",
    response: z.array(PartnerSummarySchema),
  },
  {
    method: "post",
    path: "/api/sites",
    alias: "create_site",
    description: `Create a Site and its corresponding structure (Site &#x3D;&gt; DeliveryPoints &#x3D;&gt; Assets)`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SiteSchemaCreate,
      },
    ],
    response: z.string().uuid(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid",
    alias: "get_site",
    description: `Site&#x27;s Physical Description.

The powercurve will be a list of tuple [wind_speed (m/s), power (kW)] like so:
- [..., [4.0, 92.1], [4.5, 141.7], [5.0, 200.8], ...]`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: SiteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/sites/:uuid",
    alias: "update_site",
    description: `Update a single site`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SiteSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: SiteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/finance",
    alias: "get_site_financial_report",
    description: `Get financial report of a given site. Can get current or previous year report.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish().default("1MS"),
      },
      {
        name: "io_format",
        type: "Query",
        schema: IOFormatDF.nullish().default("feather"),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/load_factor",
    alias: "get_load_factor",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish().default("1MS"),
      },
      {
        name: "missing_left_idx_tol",
        type: "Query",
        schema: z.number().nullish().default(0.5),
      },
      {
        name: "io_format",
        type: "Query",
        schema: IOFormatDF.nullish().default("feather"),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/load_factor_quantiles",
    alias: "get_load_factor_quantiles",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish().default("1MS"),
      },
      {
        name: "quantiles",
        type: "Query",
        schema: z.array(z.number()).nullish().default([0.05, 0.95]),
      },
      {
        name: "year_range",
        type: "Query",
        schema: z.number().int().nullish().default(10),
      },
      {
        name: "extrapolation_window",
        type: "Query",
        schema: z.number().int().nullish().default(14),
      },
      {
        name: "io_format",
        type: "Query",
        schema: IOFormatDF.nullish().default("feather"),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/production",
    alias: "get_site_production_chart",
    description: `Get production chart for a given site.
missing_freq_tol and missing_data_resilient to be tuned
in order to be more or less permissive on data holes`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("1900-01-01T00:00:00+00:00")),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce
          .date()
          .nullish()
          .default(new Date("2200-01-01T00:00:00+00:00")),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish().default("1MS"),
      },
      {
        name: "unit",
        type: "Query",
        schema: z.string().nullish().default("MWh"),
      },
      {
        name: "missing_freq_tol",
        type: "Query",
        schema: z.number().nullish().default(1),
      },
      {
        name: "missing_data_resilient",
        type: "Query",
        schema: z.boolean().nullish().default(true),
      },
      {
        name: "io_format",
        type: "Query",
        schema: IOFormatDF.nullish().default("feather"),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/supervision",
    alias: "get_site_supervision_chart",
    description: `Get supervision chart for a given site.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.enum(["30T", "1D", "1MS"]).nullish(),
      },
      {
        name: "unit",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "io_format",
        type: "Query",
        schema: IOFormatDF.nullish().default("feather"),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/tender_request",
    alias: "create_tender_request",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: TenderRequestCreate,
      },
    ],
    response: z.string().uuid(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/tender_request/:external_id",
    alias: "get_tender_request",
    requestFormat: "json",
    parameters: [
      {
        name: "external_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/tender_request/:external_id",
    alias: "delete_tender_request",
    requestFormat: "json",
    parameters: [
      {
        name: "external_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/timeseries/:timeserie_id/data",
    alias: "get_timeseries_data",
    requestFormat: "json",
    parameters: [
      {
        name: "timeserie_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "version",
        type: "Query",
        schema: version,
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "unit",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "missing_freq_tol",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "io_format",
        type: "Query",
        schema: IOFormatDF.nullish().default("feather"),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/timeseries/:timeserie_id/metadata",
    alias: "get_timeseries_metadata",
    requestFormat: "json",
    parameters: [
      {
        name: "timeserie_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: TSMetadata,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/timeseries/:timeserie_id/stats",
    alias: "get_timeseries_stats",
    requestFormat: "json",
    parameters: [
      {
        name: "timeserie_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date().nullish(),
      },
    ],
    response: Statistics,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/capacity",
    alias: "contracted_capacity",
    description: `Provide a portfolio view of installed capacity split by year and contract type`,
    requestFormat: "json",
    parameters: [
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish().default("1YS"),
      },
      {
        name: "company_uuid",
        type: "Query",
        schema: z.string().uuid().nullish(),
      },
    ],
    response: z.record(TimeSeries),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/contracts/summary",
    alias: "portfolio_contract_summary",
    description: `Provide a portfolio summary with number of contract per price mechanism`,
    requestFormat: "json",
    parameters: [
      {
        name: "company_uuid",
        type: "Query",
        schema: z.string().uuid().nullish(),
      },
    ],
    response: z.record(z.array(ContractSummarySchema)),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/physical",
    alias: "get_sites_topology",
    description: `Providing an excel file filled in with slected sites data.
If sites list is empty, all portfolio sites will be provided`,
    requestFormat: "json",
    parameters: [
      {
        name: "sites",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "company_uuid",
        type: "Query",
        schema: z.string().uuid().nullish(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/sites/summary",
    alias: "portfolio_site_summary",
    description: `Provide a portfolio summary with number of site per technology
 and corresponding total installed capacity`,
    requestFormat: "json",
    parameters: [
      {
        name: "company_uuid",
        type: "Query",
        schema: z.string().uuid().nullish(),
      },
    ],
    response: z.record(SiteSummarySchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
]);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}

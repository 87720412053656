<script setup name="NotificationCenter" lang="ts">
import { DialogTitle } from "@headlessui/vue"

const mainStore = useMainStore()

const isHovered = ref(false)

const unreadNotifs = computed(() =>
  mainStore.notifications.filter((o) => o.isRead === false).reverse(),
)
const ttl = computed(() => Math.max(...unreadNotifs.value.map((e) => e.ttl)))

const { start: startTimer, stop: stopTimer } = useTimeoutFn(
  () => {
    unreadNotifs.value.map((e) => mainStore.toggleNotifIsRead(e.uuid))
  },
  ttl,
  { immediate: false },
)

watchEffect(() => {
  if (isHovered.value) {
    stopTimer()
  } else {
    startTimer()
  }
})
</script>

<template>
  <!-- Handle Notifications shown in side modal (with history) -->
  <SideModal
    v-model:show="mainStore.showNotifSideModal"
    :title="$t('title.category.notifications')"
    class="z-40"
  >
    <div class="flex flex-row items-center justify-between px-4 sm:px-6">
      <DialogTitle class="text-2xl font-medium text-gray-500"> Notifications </DialogTitle>
      <UButton
        :label="$t('components.management.clear')"
        color="cool"
        variant="soft"
        @click="mainStore.deleteAllNotifications"
      />
    </div>

    <div class="mt-6 flex flex-col gap-y-4 px-4 sm:px-6">
      <UNotification
        v-for="notif in mainStore.notifications"
        :id="notif.uuid"
        :key="notif.uuid"
        :close-button="undefined"
        :icon="mapNotifLevelIcon[notif.level].icon"
        :color="mapNotifLevelIcon[notif.level].color"
        :description="notif.text"
        :timeout="0"
        :title="notif.title"
      />
    </div>
  </SideModal>

  <!-- Handle Notifications shown as toasters -->
  <ClientOnly>
    <teleport to="#notifications">
      <div
        v-element-hover="(state: boolean) => (isHovered = state)"
        class="absolute bottom-0 left-1/2 isolate z-50 mb-6 flex w-full max-w-xl -translate-x-1/2 flex-col-reverse gap-y-2"
      >
        <transition-group
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-y-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-y-0"
          leave-active-class="transform ease-in duration-300 transition"
          leave-from-class="translate-y-0 opacity-100 sm:translate-y-0"
          leave-to-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-y-2"
        >
          <UNotification
            v-for="(notif, i) in unreadNotifs"
            :id="notif.uuid"
            :key="notif.uuid"
            :class="isHovered ? '' : 'absolute'"
            :style="isHovered ? '' : `bottom: ${i * 8}px; z-index: ${50 - i};`"
            :icon="mapNotifLevelIcon[notif.level].icon"
            :color="mapNotifLevelIcon[notif.level].color"
            :description="notif.text"
            :timeout="0"
            :title="notif.title"
            :callback="() => (notif.isRead = true)"
          />
        </transition-group>
      </div>
    </teleport>
  </ClientOnly>
</template>

import { default as _91action_93WvsZVu2YCAMeta } from "/vercel/path0/apps/zefire-spa/pages/auth/[action].vue?macro=true";
import { default as authaI2K7xb7i3Meta } from "/vercel/path0/apps/zefire-spa/pages/auth.vue?macro=true";
import { default as _91aggregation_937n1s9zlrSmMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/[aggregation].vue?macro=true";
import { default as contractsJdvtoCQX0IMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/contracts.vue?macro=true";
import { default as datahealthzmb3yPgGh6Meta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/datahealth.vue?macro=true";
import { default as filesZ3c99e9I3pMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/files.vue?macro=true";
import { default as index5byCkC794XMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/index.vue?macro=true";
import { default as invoicesKV0wWjTzGkMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/invoices.vue?macro=true";
import { default as notesO9vBXAkWKbMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/notes.vue?macro=true";
import { default as portfolioz9QvnqNoq3Meta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/portfolio.vue?macro=true";
import { default as supervisionMVSCq8I7V1Meta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/supervision.vue?macro=true";
import { default as _91uuid_93PIiDSS9biyMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid].vue?macro=true";
import { default as _91kind_936gKu17aaiyMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind].vue?macro=true";
import { default as companies42lfd9xJqVMeta } from "/vercel/path0/apps/zefire-spa/pages/companies.vue?macro=true";
import { default as filesCn9tqoRc75Meta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/files.vue?macro=true";
import { default as indexDeoKr0fijUMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/index.vue?macro=true";
import { default as invoicesSDRcaoR5nHMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/invoices.vue?macro=true";
import { default as notesFBPkpO5CsgMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/notes.vue?macro=true";
import { default as _91uuid_932mlo2nHQn5Meta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid].vue?macro=true";
import { default as indexBS57a8fycaMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/index.vue?macro=true";
import { default as contractsvOjlVtwGLyMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts.vue?macro=true";
import { default as calendar8zVESAAD4MMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/calendar.vue?macro=true";
import { default as datahealthqOnADDATiyMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/datahealth.vue?macro=true";
import { default as fileslSMVyRo1HQMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/files.vue?macro=true";
import { default as indexJJGaVJsD2qMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/index.vue?macro=true";
import { default as marketLlajF6LQGyMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/market.vue?macro=true";
import { default as notes4Pa0fyC8CGMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/notes.vue?macro=true";
import { default as supervisiontVwwBXeZkMMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/supervision.vue?macro=true";
import { default as technoC0PyJ7WkObMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/techno.vue?macro=true";
import { default as dashboarduLFks7k62nMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard.vue?macro=true";
import { default as datastudiog8qVm4NZ1SMeta } from "/vercel/path0/apps/zefire-spa/pages/datastudio.vue?macro=true";
import { default as _91_91notionId_93_93FUsOFLhD9YMeta } from "/vercel/path0/apps/zefire-spa/pages/docs/[[notionId]].vue?macro=true";
import { default as docsjtarmfBB1JMeta } from "/vercel/path0/apps/zefire-spa/pages/docs.vue?macro=true";
import { default as errorZq9LRN1aDJMeta } from "/vercel/path0/apps/zefire-spa/pages/error.vue?macro=true";
import { default as filesvUrLZNGHmMMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/files.vue?macro=true";
import { default as indexAIFcvkI1ydMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/index.vue?macro=true";
import { default as _91uuid_93Z4kl0UupXTMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices/[uuid].vue?macro=true";
import { default as indexWFDVnSBLAOMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices/index.vue?macro=true";
import { default as invoicesfDBeYEGj7dMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices.vue?macro=true";
import { default as _91aggregation_93wPdem2iH1TMeta } from "/vercel/path0/apps/zefire-spa/pages/metrics/[aggregation].vue?macro=true";
import { default as partnerscIjpIaqKWSMeta } from "/vercel/path0/apps/zefire-spa/pages/partners.vue?macro=true";
import { default as assetsvfNpNKHWHXMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/assets.vue?macro=true";
import { default as calendarbAR7lJjs02Meta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/calendar.vue?macro=true";
import { default as contractsFQLF9k2AfJMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/contracts.vue?macro=true";
import { default as datahealthphmRJpvmekMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datahealth.vue?macro=true";
import { default as datastatusQD3estD2sxMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datastatus.vue?macro=true";
import { default as files3HGRjtHKXIMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/files.vue?macro=true";
import { default as indexeihIrc2WxnMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/index.vue?macro=true";
import { default as invoicesiQ2v99AmcGMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/invoices.vue?macro=true";
import { default as notesZRmkZberTJMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/notes.vue?macro=true";
import { default as supervisionmtFiGTZ7qHMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/supervision.vue?macro=true";
import { default as _91uuid_93cTazIdxA4tMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid].vue?macro=true";
import { default as indexj8u6rhGUJaMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/index.vue?macro=true";
import { default as portfolioHY88Rt1bvzMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio.vue?macro=true";
import { default as settingsnFfravseHHMeta } from "/vercel/path0/apps/zefire-spa/pages/settings.vue?macro=true";
import { default as ui_45testingpnIVJvEErnMeta } from "/vercel/path0/apps/zefire-spa/pages/ui-testing.vue?macro=true";
export default [
  {
    name: authaI2K7xb7i3Meta?.name ?? "auth",
    path: authaI2K7xb7i3Meta?.path ?? "/auth",
    meta: authaI2K7xb7i3Meta || {},
    alias: authaI2K7xb7i3Meta?.alias || [],
    redirect: authaI2K7xb7i3Meta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: _91action_93WvsZVu2YCAMeta?.name ?? "auth-action",
    path: _91action_93WvsZVu2YCAMeta?.path ?? ":action()",
    meta: _91action_93WvsZVu2YCAMeta || {},
    alias: _91action_93WvsZVu2YCAMeta?.alias || [],
    redirect: _91action_93WvsZVu2YCAMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/auth/[action].vue").then(m => m.default || m)
  }
]
  },
  {
    name: companies42lfd9xJqVMeta?.name ?? "companies",
    path: companies42lfd9xJqVMeta?.path ?? "/companies",
    meta: companies42lfd9xJqVMeta || {},
    alias: companies42lfd9xJqVMeta?.alias || [],
    redirect: companies42lfd9xJqVMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies.vue").then(m => m.default || m),
    children: [
  {
    name: _91kind_936gKu17aaiyMeta?.name ?? "companies-kind",
    path: _91kind_936gKu17aaiyMeta?.path ?? ":kind()",
    meta: _91kind_936gKu17aaiyMeta || {},
    alias: _91kind_936gKu17aaiyMeta?.alias || [],
    redirect: _91kind_936gKu17aaiyMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind].vue").then(m => m.default || m),
    children: [
  {
    name: _91uuid_93PIiDSS9biyMeta?.name ?? undefined,
    path: _91uuid_93PIiDSS9biyMeta?.path ?? ":uuid()",
    meta: _91uuid_93PIiDSS9biyMeta || {},
    alias: _91uuid_93PIiDSS9biyMeta?.alias || [],
    redirect: _91uuid_93PIiDSS9biyMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid].vue").then(m => m.default || m),
    children: [
  {
    name: _91aggregation_937n1s9zlrSmMeta?.name ?? "companies-kind-uuid-aggregation",
    path: _91aggregation_937n1s9zlrSmMeta?.path ?? ":aggregation()",
    meta: _91aggregation_937n1s9zlrSmMeta || {},
    alias: _91aggregation_937n1s9zlrSmMeta?.alias || [],
    redirect: _91aggregation_937n1s9zlrSmMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/[aggregation].vue").then(m => m.default || m)
  },
  {
    name: contractsJdvtoCQX0IMeta?.name ?? "companies-kind-uuid-contracts",
    path: contractsJdvtoCQX0IMeta?.path ?? "contracts",
    meta: contractsJdvtoCQX0IMeta || {},
    alias: contractsJdvtoCQX0IMeta?.alias || [],
    redirect: contractsJdvtoCQX0IMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/contracts.vue").then(m => m.default || m)
  },
  {
    name: datahealthzmb3yPgGh6Meta?.name ?? "companies-kind-uuid-datahealth",
    path: datahealthzmb3yPgGh6Meta?.path ?? "datahealth",
    meta: datahealthzmb3yPgGh6Meta || {},
    alias: datahealthzmb3yPgGh6Meta?.alias || [],
    redirect: datahealthzmb3yPgGh6Meta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/datahealth.vue").then(m => m.default || m)
  },
  {
    name: filesZ3c99e9I3pMeta?.name ?? "companies-kind-uuid-files",
    path: filesZ3c99e9I3pMeta?.path ?? "files",
    meta: filesZ3c99e9I3pMeta || {},
    alias: filesZ3c99e9I3pMeta?.alias || [],
    redirect: filesZ3c99e9I3pMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/files.vue").then(m => m.default || m)
  },
  {
    name: index5byCkC794XMeta?.name ?? "companies-kind-uuid",
    path: index5byCkC794XMeta?.path ?? "",
    meta: index5byCkC794XMeta || {},
    alias: index5byCkC794XMeta?.alias || [],
    redirect: index5byCkC794XMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: invoicesKV0wWjTzGkMeta?.name ?? "companies-kind-uuid-invoices",
    path: invoicesKV0wWjTzGkMeta?.path ?? "invoices",
    meta: invoicesKV0wWjTzGkMeta || {},
    alias: invoicesKV0wWjTzGkMeta?.alias || [],
    redirect: invoicesKV0wWjTzGkMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/invoices.vue").then(m => m.default || m)
  },
  {
    name: notesO9vBXAkWKbMeta?.name ?? "companies-kind-uuid-notes",
    path: notesO9vBXAkWKbMeta?.path ?? "notes",
    meta: notesO9vBXAkWKbMeta || {},
    alias: notesO9vBXAkWKbMeta?.alias || [],
    redirect: notesO9vBXAkWKbMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/notes.vue").then(m => m.default || m)
  },
  {
    name: portfolioz9QvnqNoq3Meta?.name ?? "companies-kind-uuid-portfolio",
    path: portfolioz9QvnqNoq3Meta?.path ?? "portfolio",
    meta: portfolioz9QvnqNoq3Meta || {},
    alias: portfolioz9QvnqNoq3Meta?.alias || [],
    redirect: portfolioz9QvnqNoq3Meta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: supervisionMVSCq8I7V1Meta?.name ?? "companies-kind-uuid-supervision",
    path: supervisionMVSCq8I7V1Meta?.path ?? "supervision",
    meta: supervisionMVSCq8I7V1Meta || {},
    alias: supervisionMVSCq8I7V1Meta?.alias || [],
    redirect: supervisionMVSCq8I7V1Meta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/supervision.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: contractsvOjlVtwGLyMeta?.name ?? undefined,
    path: contractsvOjlVtwGLyMeta?.path ?? "/contracts",
    meta: contractsvOjlVtwGLyMeta || {},
    alias: contractsvOjlVtwGLyMeta?.alias || [],
    redirect: contractsvOjlVtwGLyMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts.vue").then(m => m.default || m),
    children: [
  {
    name: _91uuid_932mlo2nHQn5Meta?.name ?? undefined,
    path: _91uuid_932mlo2nHQn5Meta?.path ?? ":uuid()",
    meta: _91uuid_932mlo2nHQn5Meta || {},
    alias: _91uuid_932mlo2nHQn5Meta?.alias || [],
    redirect: _91uuid_932mlo2nHQn5Meta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid].vue").then(m => m.default || m),
    children: [
  {
    name: filesCn9tqoRc75Meta?.name ?? "contracts-uuid-files",
    path: filesCn9tqoRc75Meta?.path ?? "files",
    meta: filesCn9tqoRc75Meta || {},
    alias: filesCn9tqoRc75Meta?.alias || [],
    redirect: filesCn9tqoRc75Meta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/files.vue").then(m => m.default || m)
  },
  {
    name: indexDeoKr0fijUMeta?.name ?? "contracts-uuid",
    path: indexDeoKr0fijUMeta?.path ?? "",
    meta: indexDeoKr0fijUMeta || {},
    alias: indexDeoKr0fijUMeta?.alias || [],
    redirect: indexDeoKr0fijUMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: invoicesSDRcaoR5nHMeta?.name ?? "contracts-uuid-invoices",
    path: invoicesSDRcaoR5nHMeta?.path ?? "invoices",
    meta: invoicesSDRcaoR5nHMeta || {},
    alias: invoicesSDRcaoR5nHMeta?.alias || [],
    redirect: invoicesSDRcaoR5nHMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/invoices.vue").then(m => m.default || m)
  },
  {
    name: notesFBPkpO5CsgMeta?.name ?? "contracts-uuid-notes",
    path: notesFBPkpO5CsgMeta?.path ?? "notes",
    meta: notesFBPkpO5CsgMeta || {},
    alias: notesFBPkpO5CsgMeta?.alias || [],
    redirect: notesFBPkpO5CsgMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/notes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexBS57a8fycaMeta?.name ?? "contracts",
    path: indexBS57a8fycaMeta?.path ?? "",
    meta: indexBS57a8fycaMeta || {},
    alias: indexBS57a8fycaMeta?.alias || [],
    redirect: indexBS57a8fycaMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dashboarduLFks7k62nMeta?.name ?? undefined,
    path: dashboarduLFks7k62nMeta?.path ?? "/dashboard",
    meta: dashboarduLFks7k62nMeta || {},
    alias: dashboarduLFks7k62nMeta?.alias || [],
    redirect: dashboarduLFks7k62nMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: calendar8zVESAAD4MMeta?.name ?? "dashboard-calendar",
    path: calendar8zVESAAD4MMeta?.path ?? "calendar",
    meta: calendar8zVESAAD4MMeta || {},
    alias: calendar8zVESAAD4MMeta?.alias || [],
    redirect: calendar8zVESAAD4MMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/calendar.vue").then(m => m.default || m)
  },
  {
    name: datahealthqOnADDATiyMeta?.name ?? "dashboard-datahealth",
    path: datahealthqOnADDATiyMeta?.path ?? "datahealth",
    meta: datahealthqOnADDATiyMeta || {},
    alias: datahealthqOnADDATiyMeta?.alias || [],
    redirect: datahealthqOnADDATiyMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/datahealth.vue").then(m => m.default || m)
  },
  {
    name: fileslSMVyRo1HQMeta?.name ?? "dashboard-files",
    path: fileslSMVyRo1HQMeta?.path ?? "files",
    meta: fileslSMVyRo1HQMeta || {},
    alias: fileslSMVyRo1HQMeta?.alias || [],
    redirect: fileslSMVyRo1HQMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/files.vue").then(m => m.default || m)
  },
  {
    name: indexJJGaVJsD2qMeta?.name ?? "dashboard",
    path: indexJJGaVJsD2qMeta?.path ?? "",
    meta: indexJJGaVJsD2qMeta || {},
    alias: indexJJGaVJsD2qMeta?.alias || [],
    redirect: indexJJGaVJsD2qMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: marketLlajF6LQGyMeta?.name ?? "dashboard-market",
    path: marketLlajF6LQGyMeta?.path ?? "market",
    meta: marketLlajF6LQGyMeta || {},
    alias: marketLlajF6LQGyMeta?.alias || [],
    redirect: marketLlajF6LQGyMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/market.vue").then(m => m.default || m)
  },
  {
    name: notes4Pa0fyC8CGMeta?.name ?? "dashboard-notes",
    path: notes4Pa0fyC8CGMeta?.path ?? "notes",
    meta: notes4Pa0fyC8CGMeta || {},
    alias: notes4Pa0fyC8CGMeta?.alias || [],
    redirect: notes4Pa0fyC8CGMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/notes.vue").then(m => m.default || m)
  },
  {
    name: supervisiontVwwBXeZkMMeta?.name ?? "dashboard-supervision",
    path: supervisiontVwwBXeZkMMeta?.path ?? "supervision",
    meta: supervisiontVwwBXeZkMMeta || {},
    alias: supervisiontVwwBXeZkMMeta?.alias || [],
    redirect: supervisiontVwwBXeZkMMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/supervision.vue").then(m => m.default || m)
  },
  {
    name: technoC0PyJ7WkObMeta?.name ?? "dashboard-techno",
    path: technoC0PyJ7WkObMeta?.path ?? "techno",
    meta: technoC0PyJ7WkObMeta || {},
    alias: technoC0PyJ7WkObMeta?.alias || [],
    redirect: technoC0PyJ7WkObMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/techno.vue").then(m => m.default || m)
  }
]
  },
  {
    name: datastudiog8qVm4NZ1SMeta?.name ?? "datastudio",
    path: datastudiog8qVm4NZ1SMeta?.path ?? "/datastudio",
    meta: datastudiog8qVm4NZ1SMeta || {},
    alias: datastudiog8qVm4NZ1SMeta?.alias || [],
    redirect: datastudiog8qVm4NZ1SMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/datastudio.vue").then(m => m.default || m)
  },
  {
    name: docsjtarmfBB1JMeta?.name ?? "docs",
    path: docsjtarmfBB1JMeta?.path ?? "/docs",
    meta: docsjtarmfBB1JMeta || {},
    alias: docsjtarmfBB1JMeta?.alias || [],
    redirect: docsjtarmfBB1JMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/docs.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91notionId_93_93FUsOFLhD9YMeta?.name ?? "docs-notionId",
    path: _91_91notionId_93_93FUsOFLhD9YMeta?.path ?? ":notionId?",
    meta: _91_91notionId_93_93FUsOFLhD9YMeta || {},
    alias: _91_91notionId_93_93FUsOFLhD9YMeta?.alias || [],
    redirect: _91_91notionId_93_93FUsOFLhD9YMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/docs/[[notionId]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorZq9LRN1aDJMeta?.name ?? "error",
    path: errorZq9LRN1aDJMeta?.path ?? "/error",
    meta: errorZq9LRN1aDJMeta || {},
    alias: errorZq9LRN1aDJMeta?.alias || [],
    redirect: errorZq9LRN1aDJMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/error.vue").then(m => m.default || m)
  },
  {
    name: invoicesfDBeYEGj7dMeta?.name ?? undefined,
    path: invoicesfDBeYEGj7dMeta?.path ?? "/invoices",
    meta: invoicesfDBeYEGj7dMeta || {},
    alias: invoicesfDBeYEGj7dMeta?.alias || [],
    redirect: invoicesfDBeYEGj7dMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices.vue").then(m => m.default || m),
    children: [
  {
    name: _91uuid_93Z4kl0UupXTMeta?.name ?? undefined,
    path: _91uuid_93Z4kl0UupXTMeta?.path ?? ":uuid()",
    meta: _91uuid_93Z4kl0UupXTMeta || {},
    alias: _91uuid_93Z4kl0UupXTMeta?.alias || [],
    redirect: _91uuid_93Z4kl0UupXTMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices/[uuid].vue").then(m => m.default || m),
    children: [
  {
    name: filesvUrLZNGHmMMeta?.name ?? "invoices-uuid-files",
    path: filesvUrLZNGHmMMeta?.path ?? "files",
    meta: filesvUrLZNGHmMMeta || {},
    alias: filesvUrLZNGHmMMeta?.alias || [],
    redirect: filesvUrLZNGHmMMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/files.vue").then(m => m.default || m)
  },
  {
    name: indexAIFcvkI1ydMeta?.name ?? "invoices-uuid",
    path: indexAIFcvkI1ydMeta?.path ?? "",
    meta: indexAIFcvkI1ydMeta || {},
    alias: indexAIFcvkI1ydMeta?.alias || [],
    redirect: indexAIFcvkI1ydMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexWFDVnSBLAOMeta?.name ?? "invoices",
    path: indexWFDVnSBLAOMeta?.path ?? "",
    meta: indexWFDVnSBLAOMeta || {},
    alias: indexWFDVnSBLAOMeta?.alias || [],
    redirect: indexWFDVnSBLAOMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91aggregation_93wPdem2iH1TMeta?.name ?? "metrics-aggregation",
    path: _91aggregation_93wPdem2iH1TMeta?.path ?? "/metrics/:aggregation()",
    meta: _91aggregation_93wPdem2iH1TMeta || {},
    alias: _91aggregation_93wPdem2iH1TMeta?.alias || [],
    redirect: _91aggregation_93wPdem2iH1TMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/metrics/[aggregation].vue").then(m => m.default || m)
  },
  {
    name: partnerscIjpIaqKWSMeta?.name ?? "partners",
    path: partnerscIjpIaqKWSMeta?.path ?? "/partners",
    meta: partnerscIjpIaqKWSMeta || {},
    alias: partnerscIjpIaqKWSMeta?.alias || [],
    redirect: partnerscIjpIaqKWSMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: portfolioHY88Rt1bvzMeta?.name ?? undefined,
    path: portfolioHY88Rt1bvzMeta?.path ?? "/portfolio",
    meta: portfolioHY88Rt1bvzMeta || {},
    alias: portfolioHY88Rt1bvzMeta?.alias || [],
    redirect: portfolioHY88Rt1bvzMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio.vue").then(m => m.default || m),
    children: [
  {
    name: _91uuid_93cTazIdxA4tMeta?.name ?? undefined,
    path: _91uuid_93cTazIdxA4tMeta?.path ?? ":uuid()",
    meta: _91uuid_93cTazIdxA4tMeta || {},
    alias: _91uuid_93cTazIdxA4tMeta?.alias || [],
    redirect: _91uuid_93cTazIdxA4tMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid].vue").then(m => m.default || m),
    children: [
  {
    name: assetsvfNpNKHWHXMeta?.name ?? "portfolio-uuid-assets",
    path: assetsvfNpNKHWHXMeta?.path ?? "assets",
    meta: assetsvfNpNKHWHXMeta || {},
    alias: assetsvfNpNKHWHXMeta?.alias || [],
    redirect: assetsvfNpNKHWHXMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/assets.vue").then(m => m.default || m)
  },
  {
    name: calendarbAR7lJjs02Meta?.name ?? "portfolio-uuid-calendar",
    path: calendarbAR7lJjs02Meta?.path ?? "calendar",
    meta: calendarbAR7lJjs02Meta || {},
    alias: calendarbAR7lJjs02Meta?.alias || [],
    redirect: calendarbAR7lJjs02Meta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/calendar.vue").then(m => m.default || m)
  },
  {
    name: contractsFQLF9k2AfJMeta?.name ?? "portfolio-uuid-contracts",
    path: contractsFQLF9k2AfJMeta?.path ?? "contracts",
    meta: contractsFQLF9k2AfJMeta || {},
    alias: contractsFQLF9k2AfJMeta?.alias || [],
    redirect: contractsFQLF9k2AfJMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/contracts.vue").then(m => m.default || m)
  },
  {
    name: datahealthphmRJpvmekMeta?.name ?? "portfolio-uuid-datahealth",
    path: datahealthphmRJpvmekMeta?.path ?? "datahealth",
    meta: datahealthphmRJpvmekMeta || {},
    alias: datahealthphmRJpvmekMeta?.alias || [],
    redirect: datahealthphmRJpvmekMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datahealth.vue").then(m => m.default || m)
  },
  {
    name: datastatusQD3estD2sxMeta?.name ?? "portfolio-uuid-datastatus",
    path: datastatusQD3estD2sxMeta?.path ?? "datastatus",
    meta: datastatusQD3estD2sxMeta || {},
    alias: datastatusQD3estD2sxMeta?.alias || [],
    redirect: datastatusQD3estD2sxMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datastatus.vue").then(m => m.default || m)
  },
  {
    name: files3HGRjtHKXIMeta?.name ?? "portfolio-uuid-files",
    path: files3HGRjtHKXIMeta?.path ?? "files",
    meta: files3HGRjtHKXIMeta || {},
    alias: files3HGRjtHKXIMeta?.alias || [],
    redirect: files3HGRjtHKXIMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/files.vue").then(m => m.default || m)
  },
  {
    name: indexeihIrc2WxnMeta?.name ?? "portfolio-uuid",
    path: indexeihIrc2WxnMeta?.path ?? "",
    meta: indexeihIrc2WxnMeta || {},
    alias: indexeihIrc2WxnMeta?.alias || [],
    redirect: indexeihIrc2WxnMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: invoicesiQ2v99AmcGMeta?.name ?? "portfolio-uuid-invoices",
    path: invoicesiQ2v99AmcGMeta?.path ?? "invoices",
    meta: invoicesiQ2v99AmcGMeta || {},
    alias: invoicesiQ2v99AmcGMeta?.alias || [],
    redirect: invoicesiQ2v99AmcGMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/invoices.vue").then(m => m.default || m)
  },
  {
    name: notesZRmkZberTJMeta?.name ?? "portfolio-uuid-notes",
    path: notesZRmkZberTJMeta?.path ?? "notes",
    meta: notesZRmkZberTJMeta || {},
    alias: notesZRmkZberTJMeta?.alias || [],
    redirect: notesZRmkZberTJMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/notes.vue").then(m => m.default || m)
  },
  {
    name: supervisionmtFiGTZ7qHMeta?.name ?? "portfolio-uuid-supervision",
    path: supervisionmtFiGTZ7qHMeta?.path ?? "supervision",
    meta: supervisionmtFiGTZ7qHMeta || {},
    alias: supervisionmtFiGTZ7qHMeta?.alias || [],
    redirect: supervisionmtFiGTZ7qHMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/supervision.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexj8u6rhGUJaMeta?.name ?? "portfolio",
    path: indexj8u6rhGUJaMeta?.path ?? "",
    meta: indexj8u6rhGUJaMeta || {},
    alias: indexj8u6rhGUJaMeta?.alias || [],
    redirect: indexj8u6rhGUJaMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsnFfravseHHMeta?.name ?? "settings",
    path: settingsnFfravseHHMeta?.path ?? "/settings",
    meta: settingsnFfravseHHMeta || {},
    alias: settingsnFfravseHHMeta?.alias || [],
    redirect: settingsnFfravseHHMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: ui_45testingpnIVJvEErnMeta?.name ?? "ui-testing",
    path: ui_45testingpnIVJvEErnMeta?.path ?? "/ui-testing",
    meta: ui_45testingpnIVJvEErnMeta || {},
    alias: ui_45testingpnIVJvEErnMeta?.alias || [],
    redirect: ui_45testingpnIVJvEErnMeta?.redirect,
    component: () => import("/vercel/path0/apps/zefire-spa/pages/ui-testing.vue").then(m => m.default || m)
  }
]
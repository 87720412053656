<script setup name="SideModal" lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue"

const props = withDefaults(
  defineProps<{
    title: string
    show: boolean
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

const emit = defineEmits(["update:show"])

function onClose() {
  if (!props.disabled) {
    emit("update:show", false)
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="show" class="z-[100]">
    <Dialog as="section" static class="fixed inset-0 overflow-hidden" :open="show" @close="onClose">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-200"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="absolute inset-0 bg-gray-500/[.75] transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-y-0 right-0 flex max-w-full">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-200 sm:duration-300"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-200 sm:duration-300"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-xl">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-200"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                  <button class="rounded-md text-gray-300 hover:text-white" @click="onClose">
                    <span class="sr-only">Close panel</span>
                    <UIcon name="i-heroicons-x-mark-20-solid" class="size-6" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="h-full overflow-y-auto bg-white p-3">
                <slot name="default" />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { formDataPlugin, zodValidationPlugin } from "@zodios/core"
import axios from "axios"
import type { DeepReadonlyObject } from "@zodios/core/lib/utils.types"
import { unref } from "vue"
import * as dt from "date-fns"
import { createApiClient, endpoints } from "~/composables/codegen/api-client"
import { stringify } from "qs"

// TODO: will be removed once new client only (no need for a composable)
// eslint-disable-next-line import/no-mutable-exports
export let axiosInstance: AxiosInstance | undefined

type GetAccesTokenFn = (..._: any[]) => Promise<string>

function getAxiosInstance(
  baseUrl: string,
  access_token_fn: GetAccesTokenFn | undefined,
): AxiosInstance {
  interface RequestConfig extends AxiosRequestConfig {
    startTime?: Date
  }

  // @ts-expect-error wrong extend on generic
  interface Response extends AxiosResponse {
    config: RequestConfig
  }

  const config: RequestConfig = {
    baseURL: baseUrl,
    responseType: "json",
    headers: { "Content-Type": "application/json" },
    paramsSerializer: (params) =>
      stringify(params, {
        arrayFormat: "repeat",
        serializeDate: (date: Date) => dt.formatISO(date),
      }),
  }

  const instance = axios.create(config)
  axiosInstance = instance // TODO: remove once legacy client is no more

  // @ts-expect-error to fix
  instance.interceptors.request.use((request: RequestConfig) => {
    request.startTime = new Date()
    if (import.meta.dev) {
      console.log("Requesting ", request.url, " ...")
    }

    // console.log("Starting Request", JSON.stringify(request, null, 2));
    return request
  })

  function toLogMessage(response: Response): string {
    let msg = [response.status, response.config.method?.toUpperCase(), response.config.url].join(
      " - ",
    )

    const endTime = new Date()
    const startTime = response.config.startTime
    if (startTime) {
      const duration = endTime.getTime() - startTime.getTime()
      msg = `${msg} took ${duration} ms`
    }
    return msg
  }

  instance.interceptors.response.use(
    (response) => {
      if (import.meta.dev) {
        console.log(toLogMessage(response))
      }

      return response
    },
    (error) => {
      // https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
      if (import.meta.dev) {
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.error(toLogMessage(error.response))
          console.dir(error.response)
        } else if (error.request) {
          // client never received a response, or request never left
          // `error.request` is an instance of XMLHttpRequest in the browser
          console.dir(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error(error.message)
        }
      }

      const status_code = error.response?.status
      if (R.isNumber(status_code) && status_code === 500) {
        useMainStore().addNotification({
          title: "Query failed",
          text: error.response!.data.message || undefined,
          level: NotifLevel.enum.alert,
        })
      }

      return Promise.reject(error)
    },
  )

  if (access_token_fn) {
    instance.interceptors.request.use(async (request) => {
      const access_token = await access_token_fn()
      request.headers!.Authorization = `Bearer ${access_token}`
      return request
    })
  }

  return instance
}

export function useAxiosInstance(): AxiosInstance {
  if (!axiosInstance) {
    throw new Error("Axios instance not yet instanciated")
  } else {
    return axiosInstance
  }
}

export type ApiClientT = ReturnType<typeof createApiClient>

let client: ApiClientT | undefined

export function useApiClient(access_token_fn: GetAccesTokenFn | undefined = undefined) {
  if (client) {
    return client
  }

  const baseUrl = useRuntimeConfig().public.apiBaseUrl

  client = createApiClient(baseUrl, {
    validate: false,
    transform: false,
    sendDefaults: false,
    axiosInstance: getAxiosInstance(baseUrl, access_token_fn),
  })

  // Let's disable zod validation for specific endpoints.
  const zodiosPlugWithoutValidate = zodValidationPlugin({
    validate: "request",
    transform: "request",
    sendDefaults: false,
  })

  // For response being z.void(), fastapi still return an empty string, and it makes the zod validation fail.
  const void_response_aliases = ["delete_file_by_model", "delete_document", "delete_event"] as const

  // For responses being slow to parse (array of unions for example)
  const slow_zod_parse_aliases = [
    "get_goldensource_kpis",
    "get_sites_with_datahealth_filtered_by_model",
    "get_datahealth",
  ] as const

  const zod_disabled_aliases = [...void_response_aliases, ...slow_zod_parse_aliases]
  zod_disabled_aliases.forEach((alias) => client!.use(alias, zodiosPlugWithoutValidate))

  const zodiosPlugWithValidate = zodValidationPlugin({
    validate: true,
    transform: true,
    sendDefaults: false,
  })
  const other_aliases = endpoints
    .map((e) => e.alias)
    .filter((alias) => !zod_disabled_aliases.includes(alias as any))

  other_aliases.forEach((alias) => client!.use(alias, zodiosPlugWithValidate))

  // Custom handle for file upload endpoint (to avoid having wrong Content-Type header)
  // https://github.com/ecyrbe/zodios/issues/408
  client!.use("create_document", {
    ...zodiosPlugWithValidate,
    request: async (_api, config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": "multipart/form-data",
        },
      }
    },
  })

  function toFormData(obj: Record<string, any>): FormData {
    function appendForKey(key: string, value: any, formData: FormData): void {
      if (R.isArray(value)) {
        value.forEach((e: any) => appendForKey(e.name, e, formData))
      } else if ((value as any) instanceof File) {
        formData.append(key, value)
      } else if (R.isString(value)) {
        formData.append(key, value)
      } else {
        throw new TypeError(`Conversion for ${key} is not implemented`)
      }
    }

    const formData = new FormData()
    for (const key in obj) {
      appendForKey(key, obj[key], formData)
    }
    return formData
  }

  client!.use("create_document", {
    name: formDataPlugin().name,
    request: async (_api, config) => {
      if (!R.isObject(config.data)) {
        throw new TypeError("Zodios: multipart/form-data body must be an object")
      }
      const data = toFormData(config.data)
      return { ...config, data }
    },
  })

  return client
}

type EndpointAliases = (typeof endpoints)[number]["alias"]

type ApiEndpointConfigOpts<Key extends EndpointAliases> = ApiClientT[Key] extends (
  ...args: [DeepReadonlyObject<infer B>, DeepReadonlyObject<infer C>]
) => Promise<any>
  ? B extends { httpAgent?: any }
    ? B
    : C
  : never

type ApiEndpointBody<Key extends EndpointAliases> = ApiClientT[Key] extends (
  ...args: [DeepReadonlyObject<infer B>, DeepReadonlyObject<any>]
) => Promise<any>
  ? B extends { httpAgent?: any }
    ? never
    : B
  : never

// https://www.zodios.org/docs/client#zodiosalias
export function dispatchEndpointArgs<Key extends EndpointAliases>(
  opts: MaybeRef<Record<string, any>>,
  alias: Key,
): { body: ApiEndpointBody<Key>; config: ApiEndpointConfigOpts<Key> } {
  const endpoint = endpoints.find((e) => e.alias === alias)
  if (!endpoint) {
    throw new Error(`Could not find endpoint named: ${alias}`)
  }

  const config: Record<"params" | "queries", any> = { params: {}, queries: {} }
  let body: Record<string, any> = {}

  const parameters = "parameters" in endpoint ? endpoint.parameters : []
  const sainOpts = unref(opts)

  parameters.forEach((item) => {
    if (item.name in sainOpts) {
      if (item.type === "Path") {
        config.params[item.name] = sainOpts[item.name]
      } else if (item.type === "Query") {
        config.queries[item.name] = sainOpts[item.name]
      }
    }

    if (item.name === "body") {
      body = item.schema.parse(sainOpts)
    }
  })

  return { body, config } as any
}

type _BaseConfigT = { params?: any; queries?: any } | undefined

type FlattenApiOpts<T extends _BaseConfigT> = T extends {
  params?: DeepReadonlyObject<infer P>
  queries?: DeepReadonlyObject<infer Q>
}
  ? (P extends NonNullable<unknown> ? P : {}) & (Q extends NonNullable<unknown> ? Q : {})
  : never

export type InferGetInput<Key extends EndpointAliases> = Prettify<
  ApiEndpointConfigOpts<Key> extends _BaseConfigT
    ? FlattenApiOpts<ApiEndpointConfigOpts<Key>>
    : never
>

export type InferMutateInput<Key extends EndpointAliases> = Prettify<
  ApiEndpointConfigOpts<Key> extends _BaseConfigT
    ? FlattenApiOpts<ApiEndpointConfigOpts<Key>> & ApiEndpointBody<Key>
    : ApiEndpointBody<Key>
>

// type XGET = ApiClientT["get_goldensource_timeseries"]
// type XGETCFG = ApiEndpointConfigOpts<"get_goldensource_timeseries">
// type XGETBODY = ApiEndpointBody<"get_goldensource_timeseries">
// type XGETFLATRAW = FlattenApiOpts<ApiEndpointConfigOpts<"get_goldensource_timeseries">>
// type XGETFLAT = InferGetInput<"get_goldensource_timeseries">

// type XPOST = ApiClientT["create_event"]
// type XPOSTCFG = ApiEndpointConfigOpts<"create_event">
// type XPOSTBODY = ApiEndpointBody<"create_event">
// type XPOSTFLAT = InferGetInput<"create_event">
// type XPOSTFLATMUT = InferMutateInput<"create_event">

import {
  AssetSchema,
  BaseContractSchema,
  ContactInfoSchema,
  ContractSchema,
  EventSchema,
  EventStatusChoices,
  GSMetricName,
  GaugeModel,
  HorizonLabel,
  InvoiceCancelActions,
  InvoiceExternalStatus,
  InvoiceLineSchema,
  InvoiceSchema,
  InvoiceSchemaShort,
  InvoiceStatus,
  MachineTechno,
  MachineType,
  MetricHorizon,
  NoteQueryModelName,
  NoteSchema,
  NoteTagLevel,
  SiteSchema,
  Unit,
} from "#imports"

// ______ Metric Related ______

export const mapGSMetricNameUnit: Record<GSMetricName | "availability", Unit> = {
  [GSMetricName.enum.revenue]: Unit.enum.eur,
  [GSMetricName.enum.production]: Unit.enum.kWh,
  [GSMetricName.enum.availability_injection]: Unit.enum.dimensionless,
  [GSMetricName.enum.availability_detailed]: Unit.enum.dimensionless,
  [GSMetricName.enum.availability_energy_prorated]: Unit.enum.dimensionless,
  [GSMetricName.Enum.availability_technical]: Unit.enum.dimensionless,
  availability: Unit.enum.dimensionless,
  [GSMetricName.enum.trs]: Unit.enum.dimensionless,
  [GSMetricName.enum.trs_budget]: Unit.enum.dimensionless,
  [GSMetricName.enum.trs_budget_recalled]: Unit.enum.dimensionless,
  [GSMetricName.enum.pr]: Unit.enum.dimensionless,
  [GSMetricName.enum.pr_budget]: Unit.enum.dimensionless,
  [GSMetricName.enum.pr_budget_recalled]: Unit.enum.dimensionless,
  [GSMetricName.enum.self_consumption_rate]: Unit.enum.dimensionless,
  [GSMetricName.enum.self_production_rate]: Unit.enum.dimensionless,
  [GSMetricName.enum.coverage_rate]: Unit.enum.dimensionless,
}

export const gaugeMetricLabels: HorizonLabel[] = [
  HorizonLabel.enum.ytd,
  HorizonLabel.enum.yt_last_month,
  HorizonLabel.enum.yt_quarter,
  HorizonLabel.enum.last_year,
  HorizonLabel.enum.yesterday,
]

export const metricHorizonCommonPool: MetricHorizon[] = [
  MetricHorizon.enum.yesterday,
  MetricHorizon.enum.last_month,
  MetricHorizon.enum.yt_last_month,
  MetricHorizon.enum.ytd,
]

export const reportMetricsHorizons: MetricHorizon[] = [
  MetricHorizon.enum.yt_last_month,
  MetricHorizon.enum.ytd,
  MetricHorizon.enum.last_year,
  MetricHorizon.enum.yt_quarter,
]

export const mapMetricNameAvailableHorizons: Record<string, MetricHorizon[]> = {
  [MetricName.enum.data_health]: [MetricHorizon.enum.yt_last_month, MetricHorizon.enum.last_month],
  //
  [MetricName.enum.financial_report]: reportMetricsHorizons,
  [MetricName.enum.production_report]: reportMetricsHorizons,
  //
  [MetricName.enum.power_production]: metricHorizonCommonPool,
  [MetricName.enum.revenue]: metricHorizonCommonPool,
  [MetricName.enum.incident_energy_when_energy]: metricHorizonCommonPool,
  [MetricName.enum.availability_rate]: metricHorizonCommonPool,
  [MetricName.enum.trs]: metricHorizonCommonPool,
  [MetricName.enum.pr]: metricHorizonCommonPool,
  assetowner: [
    MetricHorizon.enum.ytd,
    MetricHorizon.enum.yt_last_month,
    MetricHorizon.enum.yesterday,
  ],
}

// __________ Techno utils __________

export function getMachineTechno(obj: SiteSchema | AssetSchema): MachineTechno {
  const mapMachineTypeTechno: { [key in MachineTechno]: MachineType[] } = {
    [MachineTechno.enum.wind_turbine]: [
      MachineType.enum.wind_turbine_onshore,
      MachineType.enum.wind_turbine_offshore,
    ],
    [MachineTechno.enum.solar_field]: [
      MachineType.enum.solar_field_canopy,
      MachineType.enum.solar_field_rooftop,
      MachineType.enum.solar_field_ground_mounted,
    ],
    [MachineTechno.enum.hydro_turbine]: [
      MachineType.enum.hydro_turbine_reservoir,
      MachineType.enum.hydro_turbine_run_of_river,
      MachineType.enum.hydro_turbine_pumped_storage,
    ],
    [MachineTechno.enum.cogeneration]: [
      MachineType.enum.cogeneration_biomass,
      MachineType.enum.cogeneration_other,
      MachineType.enum.cogeneration_waste,
    ],
  }

  if (ld.has(obj, "machine_type")) {
    // Case of Site
    const machineTechno = ld.findKey(mapMachineTypeTechno, (machine_types: MachineType[]) =>
      machine_types.includes((obj as SiteSchema).machine_type as MachineType),
    )!
    return machineTechno as MachineTechno
  } else {
    // Case of Asset
    if (ld.has(obj, "manufacturer")) {
      return MachineTechno.enum.wind_turbine
    } else if (ld.has(obj, "orientation")) {
      return MachineTechno.enum.solar_field
    } else {
      return MachineTechno.enum.hydro_turbine
    }
  }
}

export function isMachineTechno(
  obj: SiteSchema | AssetSchema,
  machineTechno: MachineTechno,
): boolean {
  return getMachineTechno(obj) === machineTechno
}

export const mapTechnoMachineTypes: Record<MachineTechno, MachineType[]> = {
  [MachineTechno.enum.solar_field]: [
    MachineType.enum.solar_field_canopy,
    MachineType.enum.solar_field_rooftop,
    MachineType.enum.solar_field_ground_mounted,
  ],
  [MachineTechno.enum.wind_turbine]: [
    MachineType.enum.wind_turbine_onshore,
    MachineType.enum.wind_turbine_offshore,
  ],
  [MachineTechno.enum.hydro_turbine]: [
    MachineType.enum.hydro_turbine_run_of_river,
    MachineType.enum.hydro_turbine_pumped_storage,
    MachineType.enum.hydro_turbine_reservoir,
  ],
  [MachineTechno.enum.cogeneration]: [
    MachineType.enum.cogeneration_biomass,
    MachineType.enum.cogeneration_waste,
    MachineType.enum.cogeneration_other,
  ],
}

// __________ Contract utils __________

export function toContractHorizonMap(contracts: BaseContractSchema[]): {
  [key: string]: BaseContractSchema[]
} {
  const now = new Date()

  function fltPast(contract: BaseContractSchema): boolean {
    return contract.end.getTime() < now.getTime()
  }

  function fltCurrent(contract: BaseContractSchema): boolean {
    return contract.start.getTime() <= now.getTime() && now.getTime() < contract.end.getTime()
  }

  function fltFutur(contract: BaseContractSchema): boolean {
    return now.getTime() < contract.start.getTime()
  }

  function groupByFn(contract: BaseContractSchema): string {
    if (fltCurrent(contract)) {
      return "current"
    } else if (fltPast(contract)) {
      return "past"
    } else if (fltFutur(contract)) {
      return "futur"
    } else {
      throw new Error("Issue on contract category")
    }
  }

  const emptyResult = { current: [], past: [], futur: [] }
  return { ...emptyResult, ...ld.groupBy(contracts, groupByFn) }
}

export function deslugContractType(str: string): string {
  const renames: { [key: string]: string } = {
    da: "PPA DA",
    fix: "PPA Fix",
  }
  str = renames[str] || str
  return str.toUpperCase().replaceAll("_", " ")
}

export function toContractRepr(
  contract: ContractSchema | BaseContractSchema,
  t: (value: string) => string,
): string {
  return R.join(
    [
      t(`site.contract_status.${contract.price_mechanism}`),
      deslugContractType(contract.type.name),
      contract.site.name,
    ],
    " - ",
  )
}

// __________ Invoices utils __________

export const mapInvoiceStatusColor: Record<InvoiceStatus, string> = {
  [InvoiceStatus.enum.error]: "red",
  [InvoiceStatus.enum.incomplete]: "yellow",
  [InvoiceStatus.enum.computed]: "rnxgreen",
  //
  [InvoiceStatus.enum.capped]: "rnxorange",
  [InvoiceStatus.enum.informative]: "rnxblue",
  //
  [InvoiceStatus.enum.draft]: "rnxorange",
  [InvoiceStatus.enum.published]: "cyan",
  [InvoiceStatus.enum.validated]: "rnxgreen",
  //
  [InvoiceStatus.enum.waiting]: "rnxorange",
  [InvoiceStatus.enum.submitting]: "blue",
  [InvoiceStatus.enum.submitted]: "sky",
  [InvoiceStatus.enum.payment]: "green",
  [InvoiceStatus.enum.rejected]: "red",
  [InvoiceStatus.enum.paid]: "green",
  [InvoiceStatus.enum.production_null]: "yellow",
  [InvoiceStatus.enum.canceled_by_credit_note]: "red",
  [InvoiceStatus.enum.to_validate]: "rnxorange",
  [InvoiceStatus.enum.to_redo]: "rnxorange",
  //
  [InvoiceStatus.enum.deleted]: "slate",
}

export function isStatusMismatch(external_status: InvoiceExternalStatus | null | undefined) {
  const mismatch_statuses = ["energy_mismatch", "price_mismatch", "energy_and_price_mismatch"]
  return R.isDefined(external_status) ? mismatch_statuses.includes(external_status) : false
}

export interface CheckWithReason {
  valid: boolean
  reason?: string
}

export function isInvoiceComputable(invoice: InvoiceSchemaShort): CheckWithReason {
  const allowedStatus: string[] = [
    InvoiceStatus.enum.error,
    InvoiceStatus.enum.waiting,
    InvoiceStatus.enum.incomplete,
    InvoiceStatus.enum.computed,
    InvoiceStatus.enum.to_redo,
    InvoiceStatus.enum.draft,
  ]
  const canCompute = allowedStatus.includes(invoice.status)
  let reason: undefined | string
  if (!canCompute) {
    reason = `${invoice.site.name}: Invoice with ${invoice.status} status cannot be computed.`
  }
  return { valid: canCompute, reason }
}

export function isInvoiceSubmittable(invoice: InvoiceSchemaShort): CheckWithReason {
  const allowedStatus: string[] = [
    InvoiceStatus.enum.draft,
    InvoiceStatus.enum.computed,
    InvoiceStatus.enum.published,
  ]
  const canSubmit =
    allowedStatus.includes(invoice.status) && !!invoice.contract.invoice_submit_channel
  let reason: undefined | string
  if (!canSubmit) {
    reason = `${invoice.site.name}: Invoice with ${invoice.status} status can be submitted.`
  }

  return { valid: canSubmit, reason }
}

export function isInvoicePublishable(invoice: InvoiceSchemaShort): CheckWithReason {
  const allowedStatus: string[] = [InvoiceStatus.enum.draft, InvoiceStatus.enum.computed]
  const canPublish =
    allowedStatus.includes(invoice.status) &&
    invoice.contract.invoice_submit_channel !== InvoiceSubmitChannel.enum.platform
  let reason: undefined | string
  if (!canPublish) {
    reason = `${invoice.site.name}: Invoice with ${invoice.status} status can be submitted.`
  }

  return { valid: canPublish, reason }
}

export function isInvoiceCancelable(
  invoice: InvoiceSchemaShort,
  cancel_action: InvoiceCancelActions,
): CheckWithReason {
  if (invoice.contract.invoice_submit_channel === InvoiceSubmitChannel.enum.platform) {
    return {
      valid: false,
      reason: `${invoice.site.name}: Invoice with submit chanel ${invoice.contract.invoice_submit_channel} can't be canceled`,
    }
  }

  let canCancel: boolean = false

  if (cancel_action === InvoiceCancelActions.enum.unpublish) {
    canCancel = invoice.status === InvoiceStatus.enum.published
  }
  if (
    cancel_action === InvoiceCancelActions.enum.reject ||
    cancel_action === InvoiceCancelActions.enum.void_and_replace ||
    cancel_action === InvoiceCancelActions.enum.credit_note
  ) {
    canCancel =
      invoice.status === InvoiceStatus.enum.published ||
      invoice.status === InvoiceStatus.enum.submitted
  }

  let reason: undefined | string
  if (!canCancel) {
    reason = `${invoice.site.name}: Invoice with ${invoice.status} status can't be canceled.`
  }

  return { valid: canCancel, reason }
}

export function isInvoiceSubmittableToZero(invoice: InvoiceSchemaShort): CheckWithReason {
  const validStatuses = [
    InvoiceStatus.enum.waiting,
    InvoiceStatus.enum.error,
    InvoiceStatus.enum.incomplete,
    InvoiceStatus.enum.computed,
    InvoiceStatus.enum.to_redo,
  ] as string[]
  const canSubmit =
    validStatuses.includes(invoice.status as string) &&
    invoice.contract.invoice_submit_channel === InvoiceSubmitChannel.enum.platform

  let reason: undefined | string
  if (!canSubmit) {
    reason = `${invoice.site.name}: Invoice with ${invoice.status} status can't be submitted to zero.`
  }

  return { valid: canSubmit, reason }
}

export function hasInvoiceResults(invoice: InvoiceSchemaShort): boolean {
  return Boolean(invoice.lines.length + invoice.external_lines.length)
}

export function invoiceHasExcel(invoice: InvoiceSchemaShort): boolean {
  return Boolean(invoice.lines.length)
}

export function getInvoiceTotalAmount(invoice: InvoiceSchemaShort) {
  const total = ld.find(
    invoice.lines,
    (line: InvoiceLineSchema) => line.product === "total",
  )?.amount
  const externalTotal = ld.find(
    invoice.external_lines,
    (line: InvoiceLineSchema) => line.product === "total",
  )?.amount
  return externalTotal || total
}

interface TotalLine {
  quantity: number
  amount: number
  price: number
}

function _aggregateInvoiceLines(lines: InvoiceLineSchema[]): TotalLine {
  if (lines.length === 0) {
    throw new Error("empty lines")
  }

  let summableLines = lines
  if (lines.length > 1) {
    summableLines = ld(lines).filter("is_summable").value()
  }

  const quantity = ld(summableLines).map("quantity").sum()
  const price = ld(summableLines).map("price").mean()
  const amount = ld(lines).map("amount").sum()

  let aggLine = { quantity, amount, price }

  // Careful to cleanup potential NaNs
  aggLine = R.mapValues(aggLine, (value) => (Number.isNaN(value) ? 0 : value))

  return aggLine
}

export interface InvoiceTotalLines {
  total_line?: TotalLine
  total_external_line?: TotalLine
  bestof_total_line?: TotalLine
}

export function computeTotalLinesOnInvoice<T extends InvoiceSchema | InvoiceSchemaShort>(
  input: T,
): T & InvoiceTotalLines {
  // clone to avoid readonly issues
  const value = R.clone(input) as T & InvoiceTotalLines

  if (value.lines.length) {
    value.total_line = _aggregateInvoiceLines(value.lines)
  }

  if (value.external_lines.length) {
    value.total_external_line = _aggregateInvoiceLines(value.external_lines)
  }

  if (!(value.total_line || value.total_external_line)) {
    value.bestof_total_line = undefined
  } else {
    value.bestof_total_line = {
      quantity: ld.get(value, "total_external_line.quantity", ld.get(value, "total_line.quantity")),
      price: ld.get(value, "total_external_line.price", ld.get(value, "total_line.price")),
      amount: ld.get(value, "total_external_line.amount", ld.get(value, "total_line.amount")),
    }
  }

  return value
}

export function formatInvoiceLine(
  invoice: (InvoiceSchemaShort | InvoiceSchema) & InvoiceTotalLines,
  opts: {
    key: "quantity" | "price" | "amount"
    unit?: Unit
  },
  i18n: { t: (value: string) => string; n: (value: number, cfg: any) => string },
): string | undefined {
  if (!isStatusMismatch(invoice.external_status)) {
    return undefined
  }

  const mapKeyUnit: { [k in typeof opts.key]: Unit } = {
    quantity: Unit.enum.MWh,
    price: Unit.enum["eur/MWh"],
    amount: Unit.enum.eur,
  }

  const toUnit = opts.unit || mapKeyUnit[opts.key]
  const conversion_rate = getUnitConversionRate(mapKeyUnit[opts.key], toUnit)

  const internal_value = invoice.total_line?.[opts.key]
  const external_value = invoice.total_external_line?.[opts.key]

  if (internal_value === undefined || external_value === undefined) {
    return undefined
  }

  const convAndFmt = (v: number) => i18n.n(v * conversion_rate, toUnit)

  const mapKeySubject: { [k in typeof opts.key]: string } = {
    quantity: "Prod",
    price: "Price",
    amount: "Amount",
  }

  const unit_str = i18n.t(`unit.${toUnit}`)
  let content = [
    `<td>External ${mapKeySubject[opts.key]}</td>
     <td>${convAndFmt(external_value)} ${unit_str}</td>
    `,
    `<td>Internal ${mapKeySubject[opts.key]}</td>
     <td>${convAndFmt(internal_value)} ${unit_str}</td>
    `,
  ]

  if (opts.key === "quantity" && Object.keys(invoice).includes("details")) {
    const details = (invoice as InvoiceSchema).details
    if (details.length === 1) {
      const clause_details = details[0]

      const conso = ld.get(clause_details, "extra.conso")
      if (typeof conso === "number" && conso !== 0) {
        const conso_rounded = i18n.n(conso, Unit.enum.kWh)
        content = [...content, `<td>Conso</td> <td>${conso_rounded} kWh</td>`]
      }
    }
  }

  const contentDivs = content.map((value) => `<tr>${value}</tr>`)

  return `
    <table class="table-auto text-right border-spacing-x-4 border-separate">
      ${contentDivs.join("")}
    </table>`
}

// __________ Partner utils __________

export function getImageLink(contact_info: ContactInfoSchema): string {
  if (contact_info.image_link === undefined || contact_info.image_link === null) {
    return `https://eu.ui-avatars.com/api/?name=${contact_info.firstname}+${contact_info.lastname}`
  }

  return contact_info.image_link
}

// __________ Gauges utils __________

export const gaugeFn = {
  rate: (value: number | null | undefined, versus: number | null | undefined) => {
    if (R.isDefined(value) && R.isDefined(versus) && versus !== 0) {
      return (value - versus) / Math.abs(versus)
    }
  },
} as const

export function convertGaugeUnit(
  gauge: GaugeModel,
  unit: Unit | undefined,
): GaugeModel & { unit: Unit } {
  if (unit === undefined) {
    unit = Unit.enum.dimensionless
  }

  if (typeof gauge.value !== "number") {
    return { ...gauge, unit }
  }

  let value = gauge.value!
  let toUnit: Unit = unit

  if (unit === Unit.enum.dimensionless) {
    toUnit = Unit.enum["%"]
    const ratio = getUnitConversionRate(Unit.enum.dimensionless, toUnit)
    value = ratio * value
  } else {
    const converted = autoConvertQuantity(value, unit)
    value = converted.value
    toUnit = converted.unit
  }
  return { ...gauge, value, unit: toUnit }
}

// __________ Events utils __________

export function toEventStatus(event: EventSchema): EventStatusChoices {
  if (event.event_type === EventType.enum.informative) {
    return EventStatusChoices.enum.info
  }
  if (!event.is_validated && dt.differenceInHours(event.creation_date, event.end) > 24) {
    return EventStatusChoices.enum.delayed_events
  }
  if (event.is_validated) {
    return event.event_type === EventType.enum.downgraded
      ? EventStatusChoices.enum.validated_downgraded
      : EventStatusChoices.enum.validated_stopped
  }
  if (event.source === "ai") {
    return EventStatusChoices.enum.ai
  }
  return event.event_type === EventType.enum.downgraded
    ? EventStatusChoices.enum.not_validated_downgraded
    : EventStatusChoices.enum.not_validated_stopped
}

export const mapEventTypeColorLabel: { [key: string]: string } = {
  [EventStatusChoices.enum.not_validated_downgraded]: "#C5EFE1",
  [EventStatusChoices.enum.not_validated_stopped]: "#FACF99",
  [EventStatusChoices.enum.validated_downgraded]: "#43CB9C",
  [EventStatusChoices.enum.validated_stopped]: "#F78B69",
  [EventStatusChoices.enum.ai]: "#5279D8",
  [EventStatusChoices.enum.delayed_events]: "#ADC0ED",
  [EventStatusChoices.enum.info]: "#6FD7B3",
}

export function eventColor(event: EventSchema): string {
  return mapEventTypeColorLabel[toEventStatus(event)]
}

// __________ Business Objects utils __________

export function deduceBusinessObj(obj: Record<string, any>): "site" | "contract" | "invoice" {
  if ("network_voltage" in obj && "commissioning" in obj) {
    return "site" as const
  } else if ("is_birthday" in obj && "is_accounted" in obj) {
    return "invoice" as const
  } else if ("purchase_order" in obj && "offtaker" in obj) {
    return "contract" as const
  } else {
    throw new Error("Could not categorize object")
  }
}

// __________ Note business utils __________

export function getNoteLevelAgg(notes: NoteSchema[]): NoteTagLevel | undefined {
  const levelOrdeRMap: Record<NoteTagLevel, number> = {
    error: 0,
    warning: 1,
    action: 2,
    info: 3,
  }

  const levels: NoteTagLevel[] = R.pipe(
    notes,
    R.map((note) => note.level),
    R.filter((level) => level !== null && level !== undefined),
    R.uniq,
    R.sortBy((level) => levelOrdeRMap[level]),
  )

  if (levels.length === 0) {
    return undefined
  } else {
    return levels[0]
  }
}

export function getNotePath(target_model: NoteQueryModelName, target_uuid: string): string {
  let path: string = `/${target_model}/${target_uuid}`

  const company_models = [
    NoteQueryModelName.enum.assetmanager,
    NoteQueryModelName.enum.assetowner,
    NoteQueryModelName.enum.holding,
  ] as string[]

  if (company_models.includes(target_model)) {
    path = `/companies/${target_model}/${target_uuid}`
  } else if (target_model === NoteQueryModelName.enum.site) {
    path = `/portfolio/${target_uuid}`
  } else if (target_model === NoteQueryModelName.enum.contract) {
    path = `/contracts/${target_uuid}`
  }

  return path
}

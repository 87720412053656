import { z } from "zod"
import * as dt from "date-fns"
import { ContactInfoSchema, Unit } from "./schemas"
import { zodutils } from "./zodutils"
import type { NotificationAction } from "#ui/types"

export const Address = z.object({
  address: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  name: z.string().nullish(),
  postal_box: z.string().nullish(),
  postal_code: z.string().nullish(),
})
export type Address = z.infer<typeof Address>

export const CompanyKind = z.enum(["assetmanager", "assetowner", "offtaker", "holding"])
export type CompanyKind = z.infer<typeof CompanyKind>

const Company = z.object({
  uuid: z.string(),
  kind: CompanyKind,
  name: z.string(),
  url: z.string().nullish(),
  email: z.string().nullish(),
  telephone: z.string().nullish(),
  registration_number: z.string().nullish(),
  registration_office: z.string().nullish(),
  vat_number: z.string().nullish(),
  legal_type: z.string().nullish(),
  iban_code: z.string().nullish(),
  bic_code: z.string().nullish(),
  addresses: z.array(Address),
  contact_infos: z.array(ContactInfoSchema),
})
export type Company = z.infer<typeof Company>

export const Assetowner = Company.extend({
  kind: z.literal(CompanyKind.enum.assetowner),
})
export type Assetowner = z.infer<typeof Assetowner>

export const CompanyShort = z.object({
  uuid: z.string(),
  name: z.string(),
})
export type CompanyShort = z.infer<typeof CompanyShort>

export const Holding = Company.extend({
  kind: z.literal(CompanyKind.enum.holding),
  assetowners: z.array(CompanyShort),
})
export type Holding = z.infer<typeof Holding>

export const Token = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  token_type: z.string(),
  expires_in: z.number(), // in seconds
})
export type Token = z.infer<typeof Token>

// _______ Timeseries Models enhanced _______

export interface TSTag {
  code: string
  name: string
  rank: number
}

export type TSModel = "site" | "tender" | "contract" | "invoice"

export interface TSidsQuery {
  model: TSModel
  uuid: string
  tags: (string | string[])[]
}

export interface TSBaseQuery {
  start?: string | Date
  end?: string | Date
  version?: string | Date
  freq?: string
  unit?: string
  missing_freq_tol?: number
}

export interface TSReadQuery extends TSBaseQuery {
  tablename: string
}

export interface AggQuery extends TSidsQuery, TSBaseQuery {}

export const TSMetadata = z.object({
  freq: z.string(),
  unit: z.string(),
  horizon_start: z.coerce.date().nullish(),
  horizon_end: z.coerce.date().nullish(),
})
export type TSMetadata = z.infer<typeof TSMetadata>

export const BaseTSStats = z.object({
  min_index: z.coerce.date(),
  max_index: z.coerce.date(),
  completeness: z.number(),
  min_value: z.number(),
  max_value: z.number(),
  avg_value: z.number(),
  count_value: z.number(),
})
export type BaseTSStats = z.infer<typeof BaseTSStats>

export interface TSVersionStatistics extends BaseTSStats {
  version: string | Date
}

export const TSStatistics = BaseTSStats.extend({
  tz: z.string(),
  freq: z.string(),
  versions: z.array(BaseTSStats).nullish(),
  version_freq: z.string().nullish(),
  version_delta_horizon: z.string().nullish(),
})
export type TSStatistics = z.infer<typeof TSStatistics>

// _______ Data Studio _______

export enum DataStudioBranch {
  country = "country",
  company = "company",
}

export const SerieTagCategory = z.enum([
  "coef freq",
  "quality",
  "techno",
  "Maturity",
  "Physical Qty",
  "flow",
  "coef",
  "price",
  "signal",
  "market",
  "Way",
])
export type SerieTagCategory = z.infer<typeof SerieTagCategory>

export const SerieTag = z.object({
  code: z.string(),
  name: z.string(),
  description: z.string(),
  category: SerieTagCategory,
})
export type SerieTag = z.infer<typeof SerieTag>

export const DataStudioSerie = z.object({
  id: z.number(),
  name: z.string(),
  freq: z.string(),
  unit: z.string(),
  description: z.string(),
  sprovider: z.record(z.string(), z.any()).nullable(),
  tags: z.array(SerieTag),
  hierarchy: z.array(
    z.object({
      uuid: z.string().nullable(),
      model: z.string(),
      name: z.string(),
      metadata: z.record(z.string(), z.any()).nullable(),
    }),
  ),
})
export type DataStudioSerie = z.infer<typeof DataStudioSerie>

export interface SeriesByCategories {
  [key: string]: DataStudioSerie[]
}

export const QueryForgeParams = z.object({
  unit: Unit.optional(),
  freq: Freq.default("1MS"),
  start: z.coerce.date().optional(),
  end: z.coerce.date().optional(),
})
export type QueryForgeParams = z.infer<typeof QueryForgeParams>

export const DataStudioParams = QueryForgeParams.extend({
  freq: Freq.default("1D"),
  start: z.coerce.date().optional().default(dt.startOfYear(new Date())),
  end: z.coerce
    .date()
    .optional()
    .default(dt.addDays(dt.startOfDay(new Date()), 1)),
  selected: z.preprocess(zodutils.ensureArray, z.array(z.string())).nullish(),
})
export type DataStudioParams = z.infer<typeof DataStudioParams>

// _______ Notifications Models _______

export const NotifLevel = z.enum(["alert", "warning", "info", "success"])
export type NotifLevel = z.infer<typeof NotifLevel>

export interface BNotif<T = any> {
  // optional keys are being defaulted when using mainStore.addNotification
  uuid: string
  title: string
  text: string
  createdAt: Date
  icon: string

  // identification keys
  level: NotifLevel
  actions?: NotificationAction[]

  // Display keys
  ttl: number // ms before auto-hide notif
  isRead: boolean // if notification has been read by user

  // Customization per event
  data?: T
}

// _______ ToolTip _______
export type AlertType = "warning" | "error" | "validated" | "info"

// _______ Calendar _______
export const CalendarViewType = z.enum(["table", "month", "day"])
export type CalendarViewType = z.infer<typeof CalendarViewType>

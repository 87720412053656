<script setup lang="ts">
import Highcharts from "highcharts"
import { useStorage } from "@vueuse/core"
import { twJoin, twMerge } from "tailwind-merge"
import { logout } from "~/plugins/auth"
import { GSKpiAggregationCat, Scope } from "#imports"
import { highchart_french_translations, highchart_english_translations } from "~/utils/highchart"

const auth0 = useAuth0()
const mainStore = useMainStore()

const username = computed<string>(() => auth0.user.value?.name || "local-user")
const company = computed<string>(() =>
  auth0.idTokenClaims.value
    ? auth0.idTokenClaims.value["https://renewex.co"].companyName || ""
    : "impersonate",
)

const defaultAvatarSrc = "https://api.dicebear.com/6.x/identicon/svg"
const avatarSrc = computed(() => auth0.user.value?.picture || defaultAvatarSrc)

const collapsed = useStorage("UISidebarCollapsed", false)

const { t } = useI18n()

const dropdownItems = computed(() => {
  const items = [
    // [{ label: "Settings", icon: "i-fa6-solid-gear", to: "/settings" }],
    [{ label: t("components.actions.logout"), icon: "i-mdi-logout", click: () => logout(auth0) }],
  ]

  if (auth0.user.value) {
    if (auth0.user.value.email?.endsWith("@renewex.co")) {
      const mapCompanyNameOrgId = {
        "Arkolia Energies": "org_jySlLJJxvCr3OiZx",
        "Reservoir Sun": "org_yJssgBv43f9Th16g",
        "Kallista Energy": "org_9Bin3YVLbSc5u471",
        "unit-e": "org_yVnW7Dp7f2ctDt0V",
        SAEM: "org_94FwcYzhcbOxQCZt",
        "Technique Solaire": "org_XcQh2O3pV7aSWTH0",
      } as const
      items.push(
        R.pipe(
          mapCompanyNameOrgId,
          R.toPairs.strict,
          R.filter(([name, _]) => name !== company.value),
          R.map(([name, org_id]) => ({
            label: name,
            icon: "i-fa6-solid-arrow-right-arrow-left",
            click: () => logout(auth0, { switchToOrgId: org_id }),
          })),
        ),
      )
    }
  }

  return items
  //
})

const route = useRoute()
const kpiAggModePref = useStorage("KPICompanyAggModeSideBar", GSKpiAggregationCat.enum.company)
const kpiAggregation = computed(() => route.params.aggregation ?? kpiAggModePref.value)
watch(
  () => route.params.aggregation as GSKpiAggregationCat | undefined,
  (newValue) => {
    if (R.isDefined(newValue) && GSKpiAggregationCat.options.includes(newValue)) {
      kpiAggModePref.value = newValue
    }
  },
)

const links = computed(() => {
  const sharedOpts: Record<string, any> = { exact: false, iconClass: "text-base" }
  if (collapsed.value) {
    sharedOpts.label = undefined
  }

  return [
    {
      label: t("sidebar.notifications"),
      to: undefined,
      icon: "i-fa6-solid-bell",
      click: () => (mainStore.showNotifSideModal = true),
    },
    { label: t("sidebar.dashboard"), to: "/dashboard", icon: "i-mdi-view-dashboard" },
    {
      label: t("sidebar.metrics"),
      to: `/metrics/${kpiAggregation.value}`,
      icon: "i-mdi-pulse",
    },
    {
      label: t("sidebar.contracts"),
      to: "/contracts",
      icon: "i-fa6-solid-file-contract",
      disabled: !hasScope(Scope.enum["read:contract"]),
    },
    { label: t("sidebar.portfolio"), to: "/portfolio", icon: "i-fa6-solid-wallet" },
    {
      label: t("sidebar.invoices"),
      to: "/invoices",
      icon: "i-fa6-solid-file-invoice-dollar",
      disabled: !hasScope(Scope.enum["read:invoice"]),
    },
    {
      label: t("sidebar.partners"),
      to: "/partners",
      icon: "i-fa6-solid-user-group",
      iconClass: "text-sm",
      disabled: !hasScope(Scope.enum["read:partner"]),
    },
    {
      label: t("sidebar.data_studio"),
      to: "/datastudio",
      icon: "i-fa6-solid-database",
      disabled: !hasScope(Scope.enum["read:datastudio"]),
    },
  ].map((e) => ({ ...sharedOpts, ...e }))
})

const ui = computed(() => {
  const baseUI = /* ui */ {
    base: "group flex flex-row space-x-2 items-center h-12 hover:bg-gray-200",
    wrapper: "mx-3 my-2 shrink grow space-y-1 overflow-y-auto",
    padding: "p-4",
    font: "text-sm truncate",
    active: "text-gray-900 bg-gray-200",
    inactive: "text-gray-500 hover:text-gray-700",
    width: "w-full",
    label: "truncate relative",
    rounded: "rounded-md",
    size: "text-sm",
    icon: {
      base: "flex-shrink-0 w-4 h-4",
      active: "text-gray-700",
      inactive: "text-gray-400 group-hover:text-gray-600",
    },
  }
  if (!collapsed.value) {
    baseUI.active += " border-l-4 border-rnxblue-400"
    baseUI.icon.base += " mr-2"
  }
  return baseUI
})

const { setLocale, locale } = useI18n()

const prefLang = useStorage<string | undefined>("LangPreference", undefined)
const itemsSelectLang = [
  { lang: "fr-FR", icon: "i-material-symbols-language-french" },
  { lang: "en-US", icon: "i-material-symbols-language-us" },
]

const defaultLang =
  itemsSelectLang.find((e) => e.lang == prefLang.value) ||
  itemsSelectLang.find((e) => e.lang == locale.value) ||
  itemsSelectLang[1]
const selectedLang = ref(defaultLang)

watch(
  selectedLang,
  (new_lang) => {
    setLocale(new_lang.lang)
    prefLang.value = new_lang.lang
    const hc_translations = {
      "fr-FR": highchart_french_translations,
      "en-US": highchart_english_translations,
    }[new_lang.lang]
    Highcharts.setOptions({ lang: hc_translations })
  },
  { immediate: true },
)
</script>

<template>
  <div
    class="flex h-full shrink-0 flex-col overflow-hidden bg-gray-100 transition-width duration-200"
    :class="collapsed ? 'w-[4.5rem]' : 'w-48'"
  >
    <UDropdown
      class="h-16 w-full border-b border-gray-200"
      :items="dropdownItems"
      :popper="{ placement: 'bottom' }"
      :ui="{ container: 'z-50' }"
    >
      <UButton class="h-16 w-full" color="white" variant="ghost">
        <div class="mx-2.5 inline-flex items-center space-x-4">
          <UAvatar size="sm" :src="avatarSrc" alt="Avatar" />

          <div v-if="!collapsed" class="w-[7.5rem] whitespace-nowrap text-left">
            <p class="block truncate font-normal">{{ username }}</p>
            <p class="block truncate font-medium">{{ company }}</p>
          </div>
        </div>
      </UButton>
    </UDropdown>

    <nav :class="ui.wrapper">
      <div v-for="(link, index) of links" :key="index">
        <component
          :is="link.disabled ? 'div' : 'ULink'"
          v-slot="slotProps"
          v-bind="R.omit(link, ['label', 'icon', 'click'])"
          :class="[
            ui.base,
            ui.padding,
            ui.width,
            ui.rounded,
            ui.font,
            ui.size,
            link.disabled ? 'cursor-not-allowed opacity-40' : '',
          ]"
          :active-class="ui.active"
          :inactive-class="ui.inactive"
          @click="() => (!link.disabled && link.click ? link.click() : undefined)"
          @keyup.enter="$event.target.blur()"
        >
          <UIcon
            v-if="link.icon"
            :name="link.icon"
            :class="
              twMerge(
                twJoin(ui.icon.base, slotProps?.isActive ? ui.icon.active : ui.icon.inactive),
                link.iconClass,
              )
            "
          />

          <span v-if="link.label" :class="twMerge(ui.label, (link as any).labelClass)">
            {{ link.label }}
          </span>
        </component>
      </div>
    </nav>

    <div class="mx-3 flex shrink-0 flex-col items-center justify-center gap-y-0">
      <UDivider v-if="!collapsed">
        <NuxtImg provider="imagekit" src="/zefire-spa/sigle_renewex.svg" class="my-2 max-w-8" />
      </UDivider>
      <UDivider v-else class="mb-5" />

      <UButton
        icon="i-heroicons-book-open"
        size="md"
        variant="ghost"
        :active-class="
          !collapsed
            ? 'border-rnxblue-400 border-l-4 text-gray-900 bg-gray-200'
            : 'text-gray-900 bg-gray-200'
        "
        :label="collapsed ? '' : 'Documentation'"
        class="p-4"
        :class="collapsed ? '' : 'w-full'"
        to="/docs"
        color="gray"
        :ui="{
          font: 'font-normal',
          icon: { size: { md: 'size-5 mb-0.5' } },
          color: { gray: { ghost: 'hover:bg-gray-200' } },
        }"
      />

      <USelectMenu
        v-model="selectedLang"
        size="md"
        color="gray"
        variant="none"
        :trailing-icon="collapsed ? '' : 'i-heroicons-chevron-up-20-solid'"
        :options="itemsSelectLang"
        :ui="{
          wrapper: collapsed ? 'pb-4 pt-2' : 'w-full ml-4 pb-4 pt-2',
          base:
            'relative focus:outline-none overflow-y-auto scroll-py-1' + collapsed ? '' : ' w-full',
        }"
        :ui-menu="{
          width: 'w-40',
        }"
      >
        <template #label>
          <div class="inline-flex items-center">
            <UIcon class="mb-0.5 size-5 text-gray-500" color="gray" :name="selectedLang.icon" />
            <p v-if="!collapsed" class="pl-2 text-gray-500">
              {{ t(selectedLang.lang) }}
            </p>
          </div>
        </template>
        <template #option="{ option }">
          <div class="inline-flex items-center gap-x-2">
            <UIcon class="mb-0.5 size-5 text-gray-500" color="gray" :name="option.icon" />
            <p v-if="!collapsed" class="text-gray-500">
              {{ t(option.lang) }}
            </p>
          </div>
        </template>
      </USelectMenu>
    </div>
  </div>
  <div class="relative">
    <UButton
      class="absolute -right-3 top-[3.1rem] z-10 shadow-md"
      size="xs"
      color="white"
      variant="solid"
      :ui="{ rounded: 'rounded-full', font: 'font-normal', icon: { size: { xs: 'text-sm' } } }"
      :icon="collapsed ? 'i-ri-arrow-right-s-line' : 'i-ri-arrow-left-s-line'"
      @click="collapsed = !collapsed"
    />
  </div>
  <NotificationCenter />
</template>
